import { useEffect } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Space, Tag } from "antd";
import Table from "antd/es/table";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import { Trans, t } from "@lingui/macro";

import { Reservation } from "@/types/reservation";
import { ContentLayout } from "@/components/layouts";
import { Button } from "@/components/elements";
import { useUrlSlideOver } from "@/hooks/useUrlSlideOver";

import { useReservationList } from "../hooks/useReservationList";
import { ReservationListFilter } from "../components/ReservationListFilter";

const newReservationSuffix = "create-new-reservation";

export function ReservationListView() {
  const navigate = useNavigate();
  const params = useParams<{ propertyId: string }>();

  const { reservations, pagination, loading, handleTableChange } = useReservationList();

  return (
    <ContentLayout sideColumn={<ReservationListFilter />}>
      <Table
        dataSource={reservations}
        pagination={{
          current: pagination.currentPage,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.id}
      >
        <Column
          title={t`Check In`}
          key="checkInDate"
          dataIndex="checkInDate"
          defaultSortOrder="descend"
          sorter={(a, b: Reservation) =>
            dayjs(b.checkInDate).unix() - dayjs(a.checkInDate).unix()
          }
        />

        <Column
          title={t`Check Out`}
          key="checkOutDate"
          dataIndex="checkOutDate"
        />

        <Column title={t`Room`} key="roomType" dataIndex="roomType" />

        <Column title={t`Guest`} key="guestName" dataIndex="guestName" />

        <Column
          title={t`# of Guests`}
          key="adults"
          dataIndex="numberOfGuests"
        />

        <Column
          title={t`Booking ID`}
          key="bookingId"
          dataIndex="bookingId"
        />

        <Column title={t`OTA`} key="ota" dataIndex="ota" />

        <Column title={t`Package`} key="packagePlan" dataIndex="plan" />

        <Column
          title={t`Multiple Room Reservation`}
          key="linkedReservations"
          dataIndex="linkedReservations"
          render={(linkedReservations) => {
            if (linkedReservations && linkedReservations.length) {
              return (
                <Tag color="default">
                  <Trans>YES</Trans>
                </Tag>
              );
            } else return null;
          }}
        />

        <Column
          title=""
          key="view"
          dataIndex="id"
          render={(reservationId: string) => (
            <Space size="small">
              <Link
                to={`/properties/${params.propertyId}/reservations/${reservationId}`}
                state={{ fromListView: true }}
              >
                <Trans>View details</Trans>
              </Link>
            </Space>
          )}
        />
      </Table>
      <Button size="lg" onClick={() => navigate(`./${newReservationSuffix}`)}>
        <Trans>New Reservation</Trans>
      </Button>

      <Outlet />
    </ContentLayout>
  );
}
