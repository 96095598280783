import React, { useEffect } from 'react';
import { Card, Col, Descriptions, Divider, List, Row, Spin, Statistic, Table, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { MockTemairazuReservationState, useMockTemairazuReservationStore } from '../../store/mockTemairazuReservationStore';
import { GlobalState, useGlobalStore } from '../../store/globalStore';
import { useMockTemairazuReservationService } from '../../services/useMockTemairazuReservationService';
import { Some } from '../../utils';
import Column from 'antd/es/table/Column';
import { Invoice } from '../../../types/invoice';

type ReservationRouteParam = {
  propertyId: string;
  reservationId: string;
}

export const MockReservationDetailView: React.FC = () => {
  const params = useParams<ReservationRouteParam>();
  const reservationInFocus = useMockTemairazuReservationStore((state: MockTemairazuReservationState) => state.reservationInFocus);
  const loading = useGlobalStore((state: GlobalState) => state.loading);

  const { getReservation } = useMockTemairazuReservationService();

  useEffect(() => {
    if (params.propertyId && params.reservationId) {
      const reservationId = parseInt(params.reservationId)
      getReservation(reservationId, params.propertyId);
    }
  }, [params.propertyId, params.reservationId]); // eslint-disable-line

  const calculateTotalTransactions = (invoices: Invoice[]) => {
    let totalTransaction = 0;
    let totalInvoice = 0;

    for (const invoice of invoices) {
      const transactionsAmount = invoice.transactions.reduce((accummulator, currentValue) => {
        return accummulator + currentValue.amount;
      }, 0);
      totalTransaction = totalTransaction + transactionsAmount;

      if (invoice.status !== "void") {
        const invoicesAmount = invoice.items.reduce((accummulator, currentValue) => {
          return accummulator + currentValue.price;
        }, 0)
        totalInvoice += invoicesAmount;
      }
    }

    return [Math.round(totalInvoice), Math.round(totalTransaction)];
  }

  return Some(reservationInFocus) ? (
    <>
      <Descriptions title="Reservation Info" bordered>
        <Descriptions.Item label="Check In" span={3}>{reservationInFocus.checkInDate}</Descriptions.Item>
        <Descriptions.Item label="Check Out" span={3}>{reservationInFocus.checkOutDate}</Descriptions.Item>
        <Descriptions.Item label="OTA Booking ID" span={3}>{reservationInFocus.temairazuOTABookingId}</Descriptions.Item>
        <Descriptions.Item label="Guest Name" span={1}>
          {reservationInFocus.guests[0].firstName + " " + reservationInFocus.guests[0].lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Room Type" span={1}>{reservationInFocus.roomType.name}</Descriptions.Item>
        <Descriptions.Item label="# of Guests" span={1}>{reservationInFocus.adults}</Descriptions.Item>
        <Descriptions.Item label="Package Plan" span={1}>{reservationInFocus.otaPlan && reservationInFocus.otaPlan.name}</Descriptions.Item>
        <Descriptions.Item label="Options">
          {
            reservationInFocus.otaPlan && reservationInFocus.otaPlan.options ? 
            reservationInFocus.otaPlan.options.map(option => (
              <Tag color="green" key={option.id}>{option.name}</Tag>
          )): null}
        </Descriptions.Item>
        <Descriptions.Item label="Booking Price">{reservationInFocus.bookingPrice}</Descriptions.Item>
        <Descriptions.Item label="Payment Status">{reservationInFocus.paymentStatus.name}</Descriptions.Item>
        <Descriptions.Item label="Total charge">
          {
            calculateTotalTransactions(reservationInFocus.invoices)[0]
          }
        </Descriptions.Item>
        <Descriptions.Item label="Total paid">
          {
            calculateTotalTransactions(reservationInFocus.invoices)[1]
          }
        </Descriptions.Item>
      </Descriptions>

      {reservationInFocus.linkedReservations && reservationInFocus.linkedReservations.length ? (
        <>
        <List 
          style={{ margin: "25px 0", maxWidth: 550 }}
          header={
            <h3>Linked Reservations</h3>
          }
          size="small"
          dataSource={reservationInFocus.linkedReservations}
          bordered
          renderItem={(reservation) => (
            <List.Item key={reservation.id}>
              <Link to={`/mockingbird/mock/properties/${params.propertyId}/reservations/${reservation.id}`}>OTA Booking ID: {reservation.temairazuOTABookingId}</Link>
            </List.Item>
          )}
        />
        </>
      ) : null}

      {reservationInFocus.invoices && reservationInFocus.invoices.length ? reservationInFocus.invoices.map((invoice, idx) => (
        <div key={invoice.id}>
          <h3 key={`invoice header ${invoice.id}`} style={{ margin: "25px 0" }}>{`Invoice - #${idx + 1}`}</h3>
          <Table 
            key={`invoice table ${invoice.id}`}
            dataSource={invoice.items}
            loading={loading}
            pagination={false}
            rowKey={(record) => record.id}>
            <Column
              title="Date"
              key="date"
              dataIndex="date"
            />
            <Column
              title="Item"
              key="itemName"
              dataIndex="name"
            />
            <Column
              title="Price per Unit"
              key="price"
              dataIndex="price"
              render={(amount) => Math.round(amount)}
            />
            <Column
              title="Quantity"
              key="quantity"
              dataIndex="quantity"
            />
            <Column
              title="Tax"
              key="taxAmount"
              dataIndex="taxAmount"
              render={(amount) => Math.round(amount)}
            />
            <Column
              title="Amount"
              key="totalAmount"
              dataIndex="totalAmount"
              render={(amount) => Math.round(amount)}
            />
          </Table>

          <h3 style={{ margin: "25px 0" }}>Transactions</h3>
          <Table
            dataSource={invoice.transactions}
            loading={loading}
            pagination={false}
            rowKey={(record) => record.id}>
            <Column
              title="Date"
              key="date"
              dataIndex="transactionDate"
            />

            <Column
              title="Description"
              key="description"
              dataIndex="description"
            />

            <Column
              title="Transaction Status"
              key="transactionStatus"
              dataIndex="transactionStatus"
            />

            <Column
              title="Amount"
              key="amount"
              dataIndex="amount"
            />
          </Table>

        </div>
      )) : null}

      <Divider orientation="left">Reservation Memo</Divider>
      <List
        bordered
        dataSource={reservationInFocus.memoTopics}
        renderItem={(item) => (
          <List.Item>
            <strong>[{item.title}]: </strong> {item.content}
          </List.Item>
        )}
      />
    </>
  ) : (<Spin tip="loading" size="default" />);
}
