import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useReservationService } from '@/services/useReservationService';
import { useGuestStore } from '@/store/guestStore';
import { useReservationStore } from '@/store/reservationStore';
import { useGlobalStore } from '@/store/globalStore';
import { useNotificationStore } from '@/store/notificationStore';
import { SurveyResponseDTO } from '@/types/surveyResponse.dto';

import { GuestInformationInputs, isUpdateReservationGuestDTO } from '../types';
import { ReservationRouteParam } from '../routes/ReservationDetailView';

const blankSurveyForm = {
  engagementChannels: {
    onlineTravelAgency: "",
    officialWebsite: false,
    instagram: false,
    twitter: false,
    facebook: false,
    otherSocialMedia: "",
    television: "",
    travelAgency: false,
    newspaper: "",
    magazine: "",
    bookOrGuide: "",
    recommendation: false,
    other: "",
  },
  reservationGroupType: "",
} as SurveyResponseDTO;


export const useGuestInformation = (onClose: () => void) => {
  const params = useParams<ReservationRouteParam>();
  const propertyId = params.propertyId as string;
  const reservationId = params.reservationId as string;
  const {
    getReservationGuests,
    updateReservationGuest,
    addReservationGuest,
    deleteReservationGuest,
    addReservationSurveyResponse,
    getReservationSurveyResponse,
    getReservation,
  } = useReservationService(propertyId);
  const guests = useGuestStore((state) => state.guests);
  const surveyResponse = useReservationStore((state) => state.surveyResponse);
  const loading = useGlobalStore((state) => state.loading);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const methods = useForm<GuestInformationInputs>();
  const { reset } = methods;
  const defaultSurveyForm = surveyResponse ? surveyResponse : blankSurveyForm;

  useEffect(() => {
    if (Boolean(reservationId)) {
      getReservationGuests(Number(reservationId));
      getReservationSurveyResponse(Number(reservationId));
    }
  }, []);

  useEffect(() => {
    reset({ 
      guests, 
      engagementChannels: surveyResponse?.engagementChannels || blankSurveyForm.engagementChannels, 
      reservationGroupType: surveyResponse?.reservationGroupType 
    });
  }, [guests, surveyResponse]);

  const onSubmit: SubmitHandler<GuestInformationInputs> = async (data) => {
    const originalData = data;
    try {
      const promises = data.guests.map(async (guest) => {
        if (isUpdateReservationGuestDTO(guest)) {
          return updateReservationGuest(Number(reservationId), guest);
        } else {
          return addReservationGuest(Number(reservationId), guest);
        }
      });

      const guestIds = data.guests.map((guest) => 
        isUpdateReservationGuestDTO(guest) && guest.id
      );
      const deletePromises = guests
        .filter((guest) => !guestIds.includes(guest.id))
        .map((guest) => deleteReservationGuest(Number(reservationId), guest.id));

      promises.push(
        addReservationSurveyResponse(Number(reservationId), {
          engagementChannels: data.engagementChannels,
          reservationGroupType: data.reservationGroupType,
        })
      );
      
      await Promise.all([...promises, ...deletePromises]);
      await getReservation(Number(reservationId));
      onClose();

      setTimeout(() => {
        addNotification({
          title: t`Guest information updated`,
          type: "success",
          timeoutMs: 1500,
        });
      });
    } catch (e: unknown) {
      addNotification({
        title: t`Unable to update guest information`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
      reset(originalData);
    }
  };

  return {
    methods,
    onSubmit,
    loading,
    guests,
    defaultSurveyForm,
  };
};
