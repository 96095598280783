import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '@lingui/macro';

import { useGlobalStore } from '../../../store/globalStore';
import InvoiceItemForm from './InvoiceItemForm';
import { ReservationInvoiceRouteParam } from '../routes/InvoiceManagementView';
import { BaseSlideOver } from '@/components/slideover/BaseSlideOver';
import { useInvoiceItemForm } from '../hooks/useInvoiceItemForm';
import { useNotificationStore } from '@/store/notificationStore';

const InvoiceItemSlideOver: React.FC = () => {
  const [open, setOpen] = useState(true);
  const params = useParams<ReservationInvoiceRouteParam>();
  const invoiceId = Number(params.invoiceId);
  const invoiceItemId = Number(params.invoiceItemId);
  const navigate = useNavigate();

  const { methods, onSubmit, invoiceItem } = useInvoiceItemForm();
  const isAdding = !invoiceItemId;
  const propertyId = params.propertyId || "";

  const loading = useGlobalStore(state => state.loading);
  const [formKey, setFormKey] = useState(0);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const handleSubmit = methods.handleSubmit(async (data) => {
    setGeneralError(null);
    try {
      await onSubmit(data);
      setOpen(false);
      addNotification({
        title: isAdding ? t`Item successfully added` : t`Item successfully updated`,
        type: "success",
        timeoutMs: 1500,
      });
    } catch (error) {
      setGeneralError(t`An error occurred while saving the item. Please try again.`);
      addNotification({
        title: isAdding ? t`Unable to add item` : t`Unable to update item`,
        type: "error",
        message: String(error),
        timeoutMs: 1500,
      });
    }
  });

  const handleClose = () => {
    if (Object.keys(methods.formState.errors).length === 0) {
      setOpen(false);
    } else {
      addNotification({
        title: t`Please fix form errors before closing`,
        type: "warning",
        timeoutMs: 1500,
      });
    }
  };

  return (
    <BaseSlideOver
      open={open}
      onClose={handleClose}
      title={isAdding ? t`Add item` : t`Edit item`}
      afterLeave={() => {
        setFormKey(prevKey => prevKey + 1);
        navigate('..');
      }}
      onSubmit={handleSubmit}
    >
      <>
        {generalError && (
          <div className="mb-4 text-red-600 text-sm">{generalError}</div>
        )}
        <InvoiceItemForm
          key={formKey}
          invoiceItem={invoiceItem}
          propertyId={propertyId}
          methods={methods}
        />
      </>
    </BaseSlideOver>
  );
}

export default InvoiceItemSlideOver;
