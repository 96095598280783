import { create } from "zustand";
import { RoomListDTOForCalendar } from "@/types/room.dto";
import { RoomTypeListDTO } from "@/types/roomType.dto";

export type RoomState = {
  readonly roomTypes: RoomTypeListDTO;
  readonly roomsForCalendar: RoomListDTOForCalendar;
  setRoomTypes: (roomTypes: RoomTypeListDTO) => void;
  setRoomsForCalendar: (roomsForCalendar: RoomListDTOForCalendar) => void;
};

export const useRoomStore = create<RoomState>((set) => ({
  roomTypes: [],
  roomsForCalendar: {},
  setRoomTypes: (roomTypes: RoomTypeListDTO) => set({ roomTypes }),
  setRoomsForCalendar: (roomsForCalendar: RoomListDTOForCalendar) => set({ roomsForCalendar }),
}));

