import { create } from 'zustand';
import { BulkInventoryResponseDTO, RoomInventoryDetailForCalendarDTO, SummaryInventoryResponseDTO } from '@/types/roomInventory.dto';

interface RoomInventoryState {
  inventory: BulkInventoryResponseDTO;
  summary: SummaryInventoryResponseDTO;
  roomInventoryDetail?: RoomInventoryDetailForCalendarDTO;
  isInventoryLoading: boolean;
  isDetailLoading: boolean;
  setInventory: (inventory: BulkInventoryResponseDTO) => void;
  setSummary: (summary: SummaryInventoryResponseDTO) => void;
  setRoomInventoryDetail: (roomInventoryDetail?: RoomInventoryDetailForCalendarDTO) => void;
  setInventoryLoading: (loading: boolean) => void;
  setDetailLoading: (loading: boolean) => void;
}

export const useRoomInventoryStore = create<RoomInventoryState>((set) => ({
  inventory: {},
  summary: {},
  roomInventoryDetail: undefined,
  isInventoryLoading: false,
  isDetailLoading: false,
  setInventory: (inventory) => set({ inventory }),
  setSummary: (summary) => set({ summary }),
  setRoomInventoryDetail: (roomInventoryDetail) => set({ roomInventoryDetail }),
  setInventoryLoading: (isInventoryLoading) => set({ isInventoryLoading }),
  setDetailLoading: (isDetailLoading) => set({ isDetailLoading }),
})); 
