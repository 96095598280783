import React from "react";
import { t } from "@lingui/macro";
import { FormProvider } from "react-hook-form";
import { UserPlusIcon } from "@heroicons/react/24/solid";

import DynamicFormGroups from "@/components/forms/DynamicFormGroups";

import { GuestInformationGuestForm } from "./GuestInformationGuestForm";
import { GuestInformationSurveyForm } from "./GuestInformationSurveyForm";
import { useGuestInformation } from "../hooks/useGuestInformation";
import { GuestInformationSlideOverProps } from "../types";
import { useUrlSlideOver } from "@/hooks/useUrlSlideOver";
import { UrlSlideOver } from "@/components/slideover/UrlSlideOver";

const blankForm = {
  firstName: "",
  firstNameFurigana: "",
  lastName: "",
  lastNameFurigana: "",
  alternateName: "",
  kanjiName: null,
  email: "",
  phone: "",
  gender: "",
  age: null,
  country: "",
  nationality: "",
  province: "",
  dob: null,
  address1: "",
  address2: "",
  zipcode: "",
  optOutEmail: false,
  isRepresentative: false,
  ageGroup: "",
  city: "",
};

const GuestInformationSlideOver: React.FC<GuestInformationSlideOverProps> = ({
  urlSuffix
}) => {
  const { closeSlideOver } = useUrlSlideOver(urlSuffix)
  const { methods, onSubmit, loading, guests, defaultSurveyForm } =
    useGuestInformation(closeSlideOver);

  if (loading || !guests.length) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <UrlSlideOver
        urlSuffix={urlSuffix}
        afterLeave={undefined}
        title={t`Edit Guest Information`}
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => methods.reset({ guests, ...defaultSurveyForm })}
      >
        <>
          <DynamicFormGroups
            name="guests"
            FormComponent={GuestInformationGuestForm}
            formMethods={methods}
            blankForm={blankForm}
            addLabel={<UserPlusIcon className="h-5 w-5 text-gray-600 " />}
          />
          <div className="ml-20 mt-3 mb-2">
            <GuestInformationSurveyForm methods={methods} />
          </div>
        </>
      </UrlSlideOver>
    </FormProvider>
  );
};

export default GuestInformationSlideOver;
