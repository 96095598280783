import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { t } from '@lingui/macro';

import { useGlobalStore } from '@/store/globalStore';
import { useRoomCalendarService } from '@/services/useRoomCalendarService';
import { useNotificationStore } from '@/store/notificationStore';
import { EventType } from '@/constants/common/roomEventTypes';
import { CreateRoomEventDTO, UpdateRoomEventDTO, RoomEventDTO } from '@/types/roomEvent.dto';
import { handleApiError } from '@/utils';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { RoomCalendarQueryParam } from '@/api/createRoomCalendarClient';

const blankEventForm = {
  dateTimeStart: '',
  dateTimeEnd: '',
  eventName: '',
  eventType: EventType.MAINTENANCE,
  description: '',
  isBlockingEvent: true,
  isDraft: false,
};

export const useRoomEventForm = (
  onClose: () => void,
  initialDate: string | null,
  roomTypeId: number,
  roomId: number,
  existingEvent?: RoomEventDTO
) => {
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();
  const currentFilters = queryString.parse(queryParams) as RoomCalendarQueryParam;

  const propertyId = useGlobalStore((state) => state.currentProperty);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const { createRoomEvent, updateRoomEvent } = useRoomCalendarService(propertyId);

  const methods = useForm<CreateRoomEventDTO | UpdateRoomEventDTO>({
    defaultValues: existingEvent
      ? {
          ...existingEvent,
          dateTimeStart: existingEvent.dateTimeStart,
          dateTimeEnd: existingEvent.dateTimeEnd,
        }
      : {
          ...blankEventForm,
          dateTimeStart: `${initialDate}T00:00` || "",
          dateTimeEnd: `${initialDate}T23:59` || "",
        },
  });

  useEffect(() => {
    if (existingEvent) {
      methods.reset({
        ...existingEvent,
        dateTimeStart: existingEvent.dateTimeStart,
        dateTimeEnd: existingEvent.dateTimeEnd,
      });
    }
  }, [existingEvent, methods]);

  const eventTypeOptions = Object.values(EventType).map(type => ({ value: type, label: type }));

  const onSubmit = useCallback(async (data: CreateRoomEventDTO | UpdateRoomEventDTO) => {
    if (!roomId) {
      addNotification({
        title: t`Error`,
        type: 'error',
        message: t`Room ID is missing`,
        timeoutMs: 1500,
      });
      return;
    }

    try {
      if (existingEvent) {
        // It's an update
        await updateRoomEvent({
          eventId: existingEvent.id,
          roomTypeId,
          roomId,
          eventData: data as UpdateRoomEventDTO,
        }, currentFilters);
        addNotification({
          title: t`Event updated successfully`,
          type: 'success',
          timeoutMs: 1500,
        });
      } else {
        // It's a new event
        await createRoomEvent({
          roomTypeId,
          roomId,
          eventData: data as CreateRoomEventDTO,
        }, currentFilters);
        addNotification({
          title: t`New event created`,
          type: 'success',
          timeoutMs: 1500,
        });
      }
      onClose();
    } catch (e: unknown) {
      const errorMessage = handleApiError(e);
      addNotification({
        title: t`Unable to save event`,
        type: 'error',
        message: errorMessage,
        timeoutMs: 3000,
      });
    }
  }, [roomId, createRoomEvent, updateRoomEvent, addNotification, onClose, existingEvent, roomTypeId]);

  return {
    methods,
    eventTypeOptions,
    onSubmit,
  };
};