import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useRoomCalendarService } from '@/services/useRoomCalendarService';
import { useReservationStore } from '@/store/reservationStore';
import { useNotificationStore } from '@/store/notificationStore';
import { RoomDTO } from '@/types/room.dto';
import { handleApiError } from '@/utils';
import queryString from 'query-string';
import { RoomCalendarQueryParam } from '@/api/createRoomCalendarClient';

export const useAssignReservation = (
  onClose: () => void,
  date: string,
  room: RoomDTO,
  currentAssignedReservationId?: number
) => {
  const params = useParams<{ propertyId: string }>();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();
  const currentFilters = queryString.parse(queryParams) as RoomCalendarQueryParam;

  const addNotification = useNotificationStore((state) => state.addNotification);
  const { getReservationsForRoomAndCheckInDate, assignRoom } = useRoomCalendarService(params.propertyId as string);
  const reservations = useReservationStore(state => state.reservations);
  const [selectedReservationId, setSelectedReservationId] = useState<number | null>(null);

  useEffect(() => {
    getReservationsForRoomAndCheckInDate({
      roomTypeId: room.roomTypeId,
      roomId: room.id,
      checkInDate: date,
    });
  }, []);

  const onSubmit = async (data: { reservationId: number }) => {
    try {
      await assignRoom({
        roomId: room.id,
        reservationId: data.reservationId,
      }, currentFilters);

      addNotification({
        title: currentAssignedReservationId ? t`Reservation reassigned successfully` : t`Reservation assigned successfully`,
        type: 'success',
        message: '',
        timeoutMs: 3000,
      });
      onClose();
    } catch (error) {
      addNotification({
        title: t`Error`,
        type: 'error',
        message: handleApiError(error),
        timeoutMs: 5000,
      });
    }
  };

  return {
    reservations,
    onSubmit,
    selectedReservationId,
    setSelectedReservationId,
    currentAssignedReservationId,
  };
};
