import { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams, Link } from "react-router-dom";
import { Card, Col, Form, Layout, Typography, Table, Row, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { useReservationService } from "../../../services/useReservationService";
import { ReservationState, useReservationStore } from "../../../store/reservationStore";
import { ReservationRouteParam } from "../../reservation/routes/ReservationDetailView";
import { Some } from "../../../utils";
import { GlobalState, useGlobalStore } from "../../../store/globalStore";
import { RoomType } from "../../../../types/roomType";
import { Room } from "../../../../types/room";
import { RoomAllocationState, useRoomAllocationStore } from "../../../store/roomAllocationStore";
import { AllocatedReservationDTO } from "../../../../types/roomAllocation.dto";
import { RoomAllocationCalendar } from "../components/RoomAllocationCalendar";
import { Button } from "../../../components/elements";

dayjs.extend(isBetween);

type Inputs = {
  roomId: number;
}

const { Title, Text } = Typography;
const { Column } = Table;
const { Footer } = Layout;

export function RoomAllocationView() {
  const params = useParams<ReservationRouteParam>();
  const { reservationId, propertyId } = params;
  const [roomId, setRoomId] = useState<number | null>()

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const reservation = useReservationStore((state: ReservationState) => state.reservation);
  const roomAllocationData = useRoomAllocationStore((state: RoomAllocationState) => state.roomAllocationData);
  const setRoomAllocationData = useRoomAllocationStore((state: RoomAllocationState) => state.setRoomAllocationData);
  const { 
    getReservation,
    getRoomAllocationDataForReservation,
    allocateRoomForReservation,
    removeRoomAllocation,
  } = useReservationService(propertyId as string);

  useEffect(() => {
    if (!reservation) {
      if (reservationId) {
        getReservation(reservationId);
      }
    }
  });

  useEffect(() => {
    if (reservation) {
      const { id, checkInDate, checkOutDate, roomDetails } = reservation;
      getRoomAllocationDataForReservation(id, {
        checkInDate,
        checkOutDate,
        roomTypeId: reservation.roomTypeDetails.id,
      });
      setRoomId(roomDetails ? roomDetails.id : null)
    }
  }, [reservation]);

  const initialValues = {
    roomId: reservation?.roomDetails?.id,
  }

  const { handleSubmit, control, watch, formState: { isSubmitting, isSubmitSuccessful } } = useForm<Inputs>({
    defaultValues: initialValues,
  });

  const navigate = useNavigate();

  if (Some(reservation) && Some(roomAllocationData)) {
    const {
      roomType,
      roomTypeDetails,
      room,
      roomDetails,
      guestName,
      numberOfGuests,
      checkOutDate,
      checkInDate,
    } = reservation;
    const stayDates = dayjs(checkInDate).format("MMMM DD, YYYY") + " - " + dayjs(checkOutDate).format("MMMM DD, YYYY");

    const availableRooms = [
      ...roomAllocationData.availableRoomsForRoomType,
    ]

    if (roomDetails) {
      const { id, name, label } = roomDetails;
      availableRooms.unshift({
        name,
        id,
        label,
        reservations: [],
        roomTypeId: roomTypeDetails.id,
        roomTypeDetails: roomTypeDetails,
        colour: roomTypeDetails.colour,
        events: [],
      });
    }

    const handleReset = () => {
      getReservation(reservationId);
    };


    const handleRoomAllocationPreview = (roomId: number | null) => {
      if (reservation && roomAllocationData) {
        const { id, checkInDate, checkOutDate, guestName, roomType, numberOfGuests } = reservation;

        setRoomId(roomId);

        const { roomsForRoomTypeAndDates } = roomAllocationData;
        const room = roomsForRoomTypeAndDates.find(r => r.id === roomId);

        if (!roomId) {
          const existingRoomAllocation = roomsForRoomTypeAndDates.find(r => r.reservations.find(res => res.id === id));
          if (existingRoomAllocation) {
            const updatedReservationsList = existingRoomAllocation.reservations.filter((val, index, arr) => {
              if (val.id === id) {
                arr.splice(index, 1)
                return true;
              }
              return false;
            });
            const updatedRoomForPreview = {...room, reservations: updatedReservationsList};
            const updatedData = roomsForRoomTypeAndDates.map(r => (r.id === roomId ? {...r, ...updatedRoomForPreview} : r));
            setRoomAllocationData({...roomAllocationData, roomsForRoomTypeAndDates: updatedData});
          }
        }

        if (room) {
          const reservationToInsert: AllocatedReservationDTO = {
            id, 
            guestName,
            roomType,
            checkInDate,
            checkOutDate,
            numberOfGuests,
            room: room.name,
          }

          // remove existing allocation first
          const existingRoomAllocation = roomsForRoomTypeAndDates.find(r => r.reservations.find(res => res.id === id));
          if (existingRoomAllocation) {
            const updatedReservationsList = existingRoomAllocation.reservations.filter((val, index, arr) => {
              if (val.id === id) {
                arr.splice(index, 1)
                return true;
              }
              return false;
            });
            const updatedRoomForPreview = {...room, reservations: updatedReservationsList};
            const updatedData = roomsForRoomTypeAndDates.map(r => (r.id === roomId ? {...r, ...updatedRoomForPreview} : r));
            setRoomAllocationData({...roomAllocationData, roomsForRoomTypeAndDates: updatedData});
          }

          const updatedRoomForPreview = {...room, reservations: [ ...room.reservations, reservationToInsert ]};
          const updatedData = roomsForRoomTypeAndDates.map(r => (r.id === roomId ? { ...r, ...updatedRoomForPreview } : r));
          setRoomAllocationData({...roomAllocationData, roomsForRoomTypeAndDates: updatedData});
        }
      }
    }

    const onSubmit: SubmitHandler<Inputs> = async ({ roomId }) => {
      if (roomId) {
        await allocateRoomForReservation(reservation.id, { reservationId: reservation.id, roomId });
      } else {
        await removeRoomAllocation(reservation.id);
      }

      setTimeout(() => {
        navigate(`/properties/${propertyId}/reservations/${reservation.id}`);
      }, 1500)
    }

    return (
      <Layout>
        <Form
          onFinish={handleSubmit(onSubmit)}
        >
          <Title style={{ paddingTop: 10 }} level={4}>Room Allocation</Title>
          <Card title={
            <Fragment>
              <UserOutlined /> {roomType} ・ (Room Number: {room || "Unallocated"}) ・ {guestName} ・ {numberOfGuests} guests・ {stayDates}
            </Fragment>
          }>
            <Text>Which available room would you like to allocate guest to?</Text>
            <br />
            <Text>Available rooms: {roomAllocationData ? availableRooms.length : 0}</Text>
            <Table
              dataSource={[reservation]}
              loading={loading}
              pagination={false}
              rowKey={(record) => record.id}
            >
              <Column
                title="Room Type"
                key="roomTypeDetails"
                dataIndex="roomTypeDetails"
                render={(roomType: RoomType) => (
                  <Fragment>
                    {roomType.name}
                  </Fragment>
                )}
              />

              <Column
                title="Current Room Number"
                key="room"
                dataIndex="room"
              />

              <Column
                title="Choose a Room Number"
                key="roomDetails"
                dataIndex="roomDetails"
                render={(room: Room) => (
                  <Form.Item>
                    <Controller
                      control={control}
                      name="roomId"
                      render={({ field }) => (
                        <Select
                          allowClear
                          style={{ maxWidth: 100 }}
                          onChange={(dataId) => {
                            const value = dataId || null;
                            field.onChange(value);
                            setRoomId(dataId)
                            handleRoomAllocationPreview(dataId);
                          }}
                          value={roomId}
                          options={availableRooms.map(room => {
                            const { id, name } = room;
                            return { value: id, label: name, key: id }
                          })}
                        />
                      )}
                    />
                  </Form.Item>
                )}
              />
            </Table>

            <RoomAllocationCalendar reservation={reservation} roomAllocationData={roomAllocationData} />

          </Card>

          <Footer style={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            background: "white",
          }}>
            <Row>
              <Col span={4}>
                <Link to={`/properties/${propertyId}/reservations/${reservation.id}`}>
                  <Button>Back</Button>
                </Link>
              </Col>
              <Col offset={14}>
                <Button
                  className="py-2 px-4"
                  type="text"
                  loading={loading}
                  onClick={handleReset}
                >Reset</Button>
                <Button 
                  type="primary"
                  loading={isSubmitting || loading} 
                  disabled={ 
                    isSubmitSuccessful ||
                    watch("roomId") === undefined ||
                    watch("roomId") === (roomDetails ? roomDetails.id : undefined)
                  }
                >
                  Proceed with Allocation
                </Button>

              </Col>
            </Row>
          </Footer>

        </Form>
      </Layout>
    )
  }

  return null;
}

