import React from "react";
import { LogoIcon } from "../icons";
import { NavItem } from "../elements/NavItem";
import { PropertySelector } from "./PropertySelector";
import { UserInfo } from "./UserInfo";
import {
  HomeIcon,
  TableCellsIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  DocumentChartBarIcon,
  ChatBubbleLeftRightIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { t } from "@lingui/macro";
import { useGlobalStore } from "../../store/globalStore";
import {
  Permission,
  PropertyPermission,
} from "../../constants/common/permissions";
import { hasOrgPermission, hasPropertyPermissions } from "../../utils";

interface SidebarContentProps {
  collapsed: boolean;
  peeked: boolean;
}

const iconClassName = "h-5 w-5 flex-shrink-0";

export const SidebarContent: React.FC<SidebarContentProps> = ({
  collapsed,
  peeked,
}) => {
  const currentProperty = useGlobalStore((state) => state.currentProperty);

  const showOrgSettings = hasOrgPermission([
    Permission.Admin,
    Permission.OrgRead,
    Permission.OrgReadWrite,
  ]);
  const showPropertySettings = hasPropertyPermissions([
    PropertyPermission.Admin,
  ]);

  return (
    <div className="flex grow flex-col gap-y-5 h-screen overflow-y-auto overflow-x-hidden">
      <LogoIcon className="h-[34px] font-semibold text-gray-600 px-1 my-3 overflow-hidden" />
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="px-1 space-y-1 whitespace-nowrap">
              {/* maintain occupied space */}
              <li className="h-[66px]">
                <PropertySelector collapsed={collapsed} peeked={peeked} />
              </li>

              <li>
                <NavItem
                  icon={<HomeIcon className={iconClassName} />}
                  label={t`Dashboard`}
                  to={`/properties/${currentProperty}/dashboard`}
                />
              </li>
              <li>
                <NavItem
                  icon={<TableCellsIcon className={iconClassName} />}
                  label={t`Room Management`}
                  to={`/properties/${currentProperty}/room-management`}
                />
              </li>
              <li>
                <NavItem
                  icon={<CalendarDaysIcon className={iconClassName} />}
                  label={t`Room Inventory`}
                  to={`/properties/${currentProperty}/room-inventory`}
                />
              </li>
              <li>
                <NavItem
                  icon={<CalendarDaysIcon className={iconClassName} />}
                  label={t`Reservation List`}
                  to={`/properties/${currentProperty}/reservations`}
                  pathRegex={
                    new RegExp(`properties/${currentProperty}/reservation`)
                  }
                />
              </li>
              <li>
                <NavItem
                  icon={<ChartBarSquareIcon className={iconClassName} />}
                  label={t`Analytics`}
                  to="#"
                />
              </li>
              <li>
                <NavItem
                  icon={<DocumentChartBarIcon className={iconClassName} />}
                  label={t`Revenue Report`}
                  to="#"
                />
              </li>
              <li>
                <NavItem
                  icon={<ChatBubbleLeftRightIcon className={iconClassName} />}
                  label={t`Compiled Memos`}
                  to="#"
                />
              </li>
            </ul>
          </li>
          <li className="mt-auto">
            <div className="flex flex-col px-1 py-3">
              <div className="space-y-1 whitespace-nowrap">
                {showPropertySettings && (
                  <NavItem
                    icon={
                      <AdjustmentsHorizontalIcon className={iconClassName} />
                    }
                    label={t`Property Settings`}
                    to="/property/settings/basic"
                    pathRegex={/property\/settings/}
                  />
                )}
                {showOrgSettings && (
                  <NavItem
                    icon={
                      <AdjustmentsHorizontalIcon className={iconClassName} />
                    }
                    label={t`Organization Settings`}
                    to="/org/settings/groups"
                    pathRegex={/org\/settings/}
                  />
                )}
                <UserInfo />
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};
