import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { t } from "@lingui/macro";

import { useRoomInventoryCalendarService } from "@/services/useRoomInventoryCalendarService";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import { useRoomInventoryStore } from "@/store/roomInventoryStore";
import { useNotificationStore } from "@/store/notificationStore";

export const useRoomInventoryDetail = (inventoryId: number) => {
  const params = useParams<{ propertyId: string }>();
  const [searchParams] = useSearchParams();
  const roomInventoryDetail = useRoomInventoryStore(state => state.roomInventoryDetail);
  const setRoomInventoryDetail = useRoomInventoryStore(state => state.setRoomInventoryDetail);
  const addNotification = useNotificationStore((state) => state.addNotification);
  const { adjustRoomInventoryTotalAvailability } = useRoomInventoryCalendarService(
    params.propertyId as string
  );

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const { 
    getRoomInventoryDetail,
  } = useRoomInventoryCalendarService(
    params.propertyId as string
  );

  useEffect(() => {
    if (inventoryId) {
      getRoomInventoryDetail(inventoryId);
    }
  }, [inventoryId]);

  const clearRoomInventoryDetail = () => {
    setRoomInventoryDetail(undefined);
  };

  const handleAdjustRoomInventoryTotalAvailability = async (
    roomInventoryId: number,
    totalAvailableRooms: number
  ) => {
    try {
      await adjustRoomInventoryTotalAvailability(roomInventoryId, totalAvailableRooms, {
        startDate: searchParams.get('startDate') || '',
      });
      await getRoomInventoryDetail(roomInventoryId);
      addNotification({
        title: t`Room inventory has been updated successfully`,
        type: "success",
        timeoutMs: 1500,
      });
    } catch (e) {
      addNotification({
        title: t`Error`,
        type: 'error',
        message: t`Unable to update room inventory`,
        timeoutMs: 1500,
      });
    }
  };

  return {
    loading,
    roomInventoryDetail,
    clearRoomInventoryDetail,
    adjustRoomInventoryTotalAvailability: handleAdjustRoomInventoryTotalAvailability,
  };
};
