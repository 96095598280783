import { KeyboardEvent } from "react";
import { classNames } from "../../../utils";
import { InputProps } from "./types";

export const baseClassNames =
  "block rounded-md border-0 py-1.5 focus:ring-2 focus:ring-inset text-sm leading-6";
export const defaultColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600";
export const disabledColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200";
export const errorColorClassNames =
  "text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500";

export function Input({
  defaultValue,
  name,
  type = "text",
  disabled = false,
  onChange,
  onEnterPress,
  placeholder,
  size = "w-60",
  value,
  error = false,
}: InputProps) {
  let activeClassNames = classNames(baseClassNames, size);
  if (disabled) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (onEnterPress) onEnterPress(e.currentTarget.value);
    }
  }

  return (
    <input
      type={type}
      name={name}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      className={activeClassNames}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
    />
  );
}
