import React, { useState } from 'react';
import { BaseSlideOver } from '@/components/slideover/BaseSlideOver';
import { useNavigate } from 'react-router-dom';


const PlaceholderSlideOver: React.FC = () => {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate();

  return (
    <BaseSlideOver
      title="Placeholder Title"
      open={open}
      onClose={() => setOpen(false)}
      afterLeave={() => navigate('..')}
    >
      <div className="p-4">
        This is a placeholder SlideOver component.
      </div>
    </BaseSlideOver>
  );
};

export default PlaceholderSlideOver;
