import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/16/solid';

interface ResizeHandleProps {
  collapsed: boolean;
  toggleSidebar: () => void;
  resizeSidebar: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const ResizeHandle: React.FC<ResizeHandleProps> = ({
  collapsed,
  toggleSidebar,
  resizeSidebar,
}) => {
  return (
    <div className="relative">
      <button
        onClick={toggleSidebar}
        className="absolute -left-3.5 top-8 p-1 border-gray-500 bg-white border rounded-full z-50"
      >
        <ChevronLeftIcon
          className={`h-5 w-5 text-gray-500 transition-transform duration-300 ${
            collapsed ? "-rotate-180" : ""
          }`}
        />
      </button>
      {!collapsed ? (
        <div
          onMouseDown={resizeSidebar}
          className="cursor-col-resize pr-0.5 bg-gray-500 absolute inset-y-0 z-20"
        />
      ) : (
        <div className="pr-px bg-gray-300 absolute inset-y-0" />
      )}
    </div>
  );
};

