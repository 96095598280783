import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useReservationService } from "@/services/useReservationService";
import { useInvoiceManagement } from "./useInvoiceManagement";
import { useMemo } from "react";
import { ReservationInvoiceRouteParam } from "../routes/InvoiceManagementView";
import { useNotificationStore } from "@/store/notificationStore";
import { CreateInvoiceItemDTO, UpdateInvoiceItemDTO } from "@/types/invoiceItem.dto";
import { useReservationStore } from "@/store/reservationStore";
import { t } from "@lingui/macro";

type Inputs = {
  name: string;
  price: number;
  taxId?: number; 
  optionId?: number;
  quantity: number;
  cost?: number;
  date?: string;
}

const defaultValues = {
  name: '',
  price: 0,
  taxId: undefined,
  optionId: undefined,
  quantity: 1,
  cost: 0,
};

export const useInvoiceItemForm = () => {
  const params = useParams<ReservationInvoiceRouteParam>();
  const propertyId = params.propertyId || "";
  const invoiceId = Number(params.invoiceId);
  const invoiceItemId = Number(params.invoiceItemId);
  const isAdding = !invoiceItemId;

  const navigate = useNavigate();
  const { selectedInvoice, tabs } = useInvoiceManagement();
  const { addReservationInvoiceItem, updateReservationInvoiceItem } = useReservationService(propertyId || "");
  const reservation = useReservationStore(state => state.reservation);
  const addNotification = useNotificationStore((state) => state.addNotification);

  const invoiceItem = useMemo(() => {
    if (!invoiceItemId) return null;
    return selectedInvoice?.items?.find((item: { id: number | null; }) => item.id === invoiceItemId) || null;
  }, [selectedInvoice, invoiceItemId]);


  const initialValues = (invoiceItem ? {
    name: invoiceItem.name,
    price: invoiceItem.unitPrice,
    taxId: invoiceItem.tax?.id,
    optionId: invoiceItem.option?.id,
    quantity: invoiceItem.quantity,
    cost: invoiceItem.cost,
  } : defaultValues) as Inputs;

  const methods = useForm<Inputs>({
    defaultValues: initialValues,
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const onSubmit = async (data: any) => {
    try {
      if (reservation) {
        if (invoiceItem) {
          await updateReservationInvoiceItem(reservation.id, {
            ...data,
            id: invoiceItem.id
          } as UpdateInvoiceItemDTO);
        } else if (invoiceId) {
          await addReservationInvoiceItem(reservation.id, invoiceId, data as CreateInvoiceItemDTO);
        }
      }
      navigate('..');

      setTimeout(() => {
        addNotification({
          title: isAdding ? t`Item successfully added` : t`Item successfully updated`,
          type: "success",
          timeoutMs: 1500,
        });
      }, 500);
    } catch (e: unknown) {
      addNotification({
        title: isAdding ? t`Unable to add item` : t`Unable to update item`,
        type: "error",
        message: String(e),
        timeoutMs: 1500,
      });
    }
  };

  return { methods, onSubmit, invoiceItem };
};