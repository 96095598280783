import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../store/globalStore';
import { useUserStore } from '../../store/userStore';
import { getUniqueProperties } from '../../utils';
import { Property } from '../../../types/property';

interface PropertySelectorProps {
  collapsed: boolean;
  peeked: boolean;
}

export const PropertySelector: React.FC<PropertySelectorProps> = ({ collapsed, peeked }) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const currentProperty = useGlobalStore((state) => state.currentProperty);
  const setCurrentProperty = useGlobalStore((state) => state.setCurrentProperty);

  const visibleProperties = React.useMemo(() => {
    if (!user) return [];
    return getUniqueProperties(user);
  }, [user]);

  const handleChangeProperty = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const propertyId = event.target.value;
    setCurrentProperty(propertyId);
    navigate(`/properties/${propertyId}/dashboard`);
  };

  if (collapsed && !peeked) {
    return null;
  }

  return (
    <div className="relative rounded-md inline-block w-full mb-6">
      <select
        value={currentProperty}
        onChange={handleChangeProperty}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-400 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-sm leading-6"
      >
        {visibleProperties?.map((property: Property) => (
          <option key={property.id} value={property.id}>
            {property.name}
          </option>
        ))}
      </select>
    </div>
  );
};

