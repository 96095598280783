import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useUrlSlideOver = (urlSuffix: string) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const shouldBeOpen = location.pathname.endsWith(urlSuffix);
    setIsOpen(shouldBeOpen);
  }, [location.pathname, urlSuffix]);

  const openSlideOver = () => {
    if (!location.pathname.endsWith(urlSuffix)) {
      navigate(`${location.pathname}/${urlSuffix}`);
    }
  };

  const closeSlideOver = () => {
    if (location.pathname.endsWith(urlSuffix)) {
      navigate(location.pathname.replace(`/${urlSuffix}`, ''));
    }
  };

  return { isOpen, openSlideOver, closeSlideOver };
};