import React, { useState, useMemo, useEffect } from "react";
import { t } from '@lingui/macro';
import { ReservationDetailDTO } from "../../../../types/reservation.dto";
import { Table } from "../../../components/datadisplay/Table";
import { ConfirmationModal } from "../../../components/elements/ConfirmationModal";
import { useNotificationStore } from "../../../store/notificationStore";
import { useReservationService } from "../../../services/useReservationService";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { ReservationRouteParam } from "../routes/ReservationDetailView";

type Props = {
  reservation: ReservationDetailDTO;
}

export function ReservationDetailOptionTable ({ reservation }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState<number | null>(null);

  const navigate = useNavigate();
  const params = useParams<ReservationRouteParam>();
  const propertyId = params.propertyId || "";

  const addNotification = useNotificationStore((state) => state.addNotification);
  const { deleteReservationInvoiceItem } = useReservationService(propertyId);

  const handleDeleteModal = (itemId: number) => {
    setItemToDeleteId(itemId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (itemToDeleteId) {
      try {
        await deleteReservationInvoiceItem(reservation.id, itemToDeleteId);

        setTimeout(() => {
          addNotification({
            title: t`Successfully deleted item`,
            type: "success",
            timeoutMs: 1000,
          });
        }, 500);
      } catch (e: unknown) {
        setTimeout(() => {
          addNotification({
            title: t`Unable to delete item`,
            type: "error",
            timeoutMs: 1000,
          });
        }, 500);
      }
    }

    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDeleteId(null);
  };

  const { invoiceItems } = reservation;

  return (
    <>
      <Table
        data={invoiceItems.map(item => (
          {
            id: item.id,
            name: item.name,
            category: item?.option?.optionCategory?.name || "Manual",
            date: item.date,
            quantity: item.quantity,
            price: item.totalAmount,
          }
        ))}
        columns={[
          {
            key: "name",
            title: t`Name`,
          },
          {
            key: "category",
            title: t`Category`,
          },
          {
            key: "date",
            title: t`Date`
          },
          {
            key: "quantity",
            title: t`Quantity`,
          },
          {
            key: "price",
            title: t`Price`,
          },
        ]}
        onEdit={(item) => {
          navigate(`./edit-invoice-item/${item.id}`);
        }}
        onDelete={(id) => handleDeleteModal(id)}
      />
      <ConfirmationModal
        title={t`Delete Option`}
        message={t`Are you sure you want to permanently delete this option from this reservation?`}
        buttonName={t`Delete`}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        isOpen={isModalOpen}
      />

      <Outlet/>
    </>
  )
}
