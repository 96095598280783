import { useCallback } from "react";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import { useRoomInventoryStore } from "@/store/roomInventoryStore";
import { createRoomInventoryCalendarClient } from "@/api/createRoomInventoryCalendarClient";
import { CreateInventoryRequestDTO, ExtendInventoryDTO } from "@/types/roomInventory.dto";

interface RoomInventoryCalendarQueryParam {
  startDate: string;
}

export function useRoomInventoryCalendarService(propertyId: string) {
  const client = createRoomInventoryCalendarClient(propertyId);

  const setError = useGlobalStore((state: GlobalState) => state.setError);
  const { 
    setInventory, 
    setSummary, 
    setRoomInventoryDetail,
    setInventoryLoading,
    setDetailLoading 
  } = useRoomInventoryStore();

  const getRoomInventoryCalendar = useCallback(async (params: RoomInventoryCalendarQueryParam) => {
    try {
      setInventoryLoading(true);

      const res = await client.getRoomInventoryCalendar(params);
      setInventory(res.inventory);
      setSummary(res.summary);
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setInventoryLoading(false);
    }
  }, [client, setInventoryLoading, setError, setInventory, setSummary]);

  const getRoomInventoryDetail = useCallback(async (roomInventoryId: number) => {
    try {
      setDetailLoading(true);

      const res = await client.getRoomInventoryDetail(roomInventoryId);
      setRoomInventoryDetail(res.inventory);
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setDetailLoading(false);
    }
  }, [client, setDetailLoading, setError, setRoomInventoryDetail]);

  const initializeInventory = useCallback(async (
    inventoryRequests: CreateInventoryRequestDTO,
    filters?: RoomInventoryCalendarQueryParam
  ) => {
    try {
      setInventoryLoading(true);

      await client.initializeInventory(inventoryRequests);
      if (filters) {
        await getRoomInventoryCalendar(filters);
      }
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setInventoryLoading(false);
    }
  }, [client, setInventoryLoading, setError, getRoomInventoryCalendar]);

  const extendInventory = useCallback(async (
    request: ExtendInventoryDTO,
    filters?: RoomInventoryCalendarQueryParam
  ) => {
    try {
      setInventoryLoading(true);

      await client.extendInventory(request);
      if (filters) {
        await getRoomInventoryCalendar(filters);
      }
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setInventoryLoading(false);
    }
  }, [client, setInventoryLoading, setError, getRoomInventoryCalendar]);

  const checkRoomTypeAvailability = useCallback(async (
    roomTypeId: number,
    params: {
      checkInDate: string;
      checkOutDate: string;
      roomsNeeded?: number;
    }
  ) => {
    try {
      setInventoryLoading(true);

      const res = await client.checkRoomTypeAvailability(roomTypeId, params);
      return res.isAvailable;
    } catch (e: unknown) {
      setError(String(e));
      return false;
    } finally {
      setInventoryLoading(false);
    }
  }, [client, setInventoryLoading, setError]);

  const adjustRoomInventoryTotalAvailability = useCallback(async (
    roomInventoryId: number,
    totalAvailableRooms: number,
    params: RoomInventoryCalendarQueryParam,
  ) => {
    try {
      setInventoryLoading(true);
      await client.adjustRoomInventoryTotalAvailability(roomInventoryId, totalAvailableRooms);
      await getRoomInventoryCalendar(params)
    } catch (e: unknown) {
      setError(String(e));
    } finally {
      setInventoryLoading(false);
    }
  }, [client, setInventoryLoading, setError]);

  return {
    getRoomInventoryCalendar,
    getRoomInventoryDetail,
    initializeInventory,
    extendInventory,
    checkRoomTypeAvailability,
    adjustRoomInventoryTotalAvailability,
  };
} 