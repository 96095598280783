import React from 'react';
import { useSidebar } from './hooks/useSidebar';
import { SidebarContent } from './SidebarContent';
import { ResizeHandle } from './ResizeHandle';

export const Sidebar: React.FC = () => {
  const {
    width,
    collapsed,
    peeked,
    isResizing,
    handleMouseOver,
    handleMouseOut,
    toggleSidebar,
    resizeSidebar
  } = useSidebar();

  return (
    <div className="relative z-20">
      <div
        style={{ width: collapsed ? 44 : width }}
        className={`duration-300 ease-in-out-cubic ${isResizing ? "transition-none" : "transition-all"
          }`}
      />
      <div
        className="fixed"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className="flex">
          <div
            style={{
              width: collapsed ? (peeked ? width : 44) : width,
            }}
            className={`h-screen duration-300 ease-in-out-cubic bg-gray-100 overflow-hidden ${isResizing ? "transition-none" : "transition-all"
              }`}
          >
            <SidebarContent collapsed={collapsed} peeked={peeked} />
          </div>
          <ResizeHandle
            collapsed={collapsed}
            toggleSidebar={toggleSidebar}
            resizeSidebar={resizeSidebar}
          />
        </div>
      </div>
    </div>
  );
};
