import { classNames } from '@/utils';
import React, { useState, useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface Tab {
  name: string;
  path: string;
  id: string;
}

interface TabsProps {
  tabs: Tab[];
  defaultActiveTab?: string;
  extraElement?: React.ReactNode;
  className?: string;
  onChange?: (tabId: string) => void;
}

export default function Tabs({ tabs, defaultActiveTab, extraElement, onChange, className = '' }: TabsProps) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0]?.id);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const currentTab = tabs.find(tab => location.pathname.endsWith(tab.path));
    if (currentTab) {
      setActiveTab(currentTab.id);
      onChange?.(currentTab.id);
    }
  }, [location.pathname, tabs, onChange]);


  const handleTabClick = useCallback((tab: Tab) => {
    setActiveTab(tab.id);
    navigate(tab.path);
    onChange?.(tab.id)
  }, [navigate]);

  return (
    <div className={className}>
      <nav className="hidden sm:flex space-x-4 items-center" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.id}
            to={tab.path}
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(tab);
            }}
            className={classNames(
              'rounded-md px-2.5 py-1.5 text-sm font-medium',
              tab.id === activeTab
                ? 'bg-gray-100 text-gray-700'
                : 'text-gray-500 hover:text-gray-700'
            )}
            aria-current={tab.id === activeTab ? 'page' : undefined}
          >
            {tab.name}
          </Link>
        ))}
        {extraElement && <div className={tabs.length ? "ml-4" : ""}>{extraElement}</div>}
      </nav>
    </div>
  );
}