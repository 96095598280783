import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';

import { SlideOver, SlideOverProps } from '@/components/slideover/SlideOver';
import { StackedInputGroup } from '@/components/forms/Input';
import { StackedSelectInputGroup } from '@/components/forms/Select';
import { TextAreaInputGroup } from '@/components/forms/Input/TextAreaInputGroup';
import { FormCheckbox } from '@/components/forms/Checkbox/Checkbox';
import { useRoomEventForm } from '../hooks/useRoomEventForm';
import { RoomEventDTO } from '@/types/roomEvent.dto';

interface EventFormSlideOverProps {
  date: string | null;
  roomId: number;
  roomTypeId: number;
  event?: RoomEventDTO;
}

const EventFormSlideOver: React.FC<EventFormSlideOverProps> = ({
  date,
  roomId,
  roomTypeId,
  event,
}) => {
  const [open, setOpen] = useState(true);
  const onClose = () => setOpen(false);
  const { methods, eventTypeOptions, onSubmit } = useRoomEventForm(onClose, date, roomTypeId, roomId, event);

  const inputGroupClassName = "grid grid-cols-3 gap-4 space-y-0 px-6 py-5";

  const renderForm = () => {
    return (
      <div className="space-y-0 divide-y divide-gray-200 py-0">
        <div>
          <StackedInputGroup
            label={t`Event Start`}
            name="dateTimeStart"
            type="datetime-local"
            required
            className={inputGroupClassName}
            options={{ required: t`Please select event start date and time` }}
          />

          <StackedInputGroup
            label={t`Event End`}
            name="dateTimeEnd"
            type="datetime-local"
            required
            className={inputGroupClassName}
            options={{ required: t`Please select event end date and time` }}
          />
        </div>
        <div>
          <StackedInputGroup
            label={t`Event Name`}
            name="eventName"
            type="text"
            className={inputGroupClassName}
            options={{ required: t`Please enter event name` }}
            required
          />

          <StackedSelectInputGroup
            label={t`Event Type`}
            className={inputGroupClassName}
            selectProps={{
              name: "eventType",
              options: eventTypeOptions,
            }}
            options={{ required: t`Please select event type` }}
          />

          <TextAreaInputGroup
            name="description"
            label={t`Description`}
            className={inputGroupClassName}
          />

          <FormCheckbox
            name="isBlockingEvent"
            label={t`Blocking Event`}
            className={inputGroupClassName}
          />

          <FormCheckbox
            name="isDraft"
            label={t`Save as Draft`}
            className={inputGroupClassName}
          />
        </div>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      <SlideOver
        open={open}
        onClose={onClose}
        title={event ? t`Edit Event` : t`Create New Event`}
        onSubmit={methods.handleSubmit(onSubmit)}
        content={renderForm()}
      />
    </FormProvider>
  );
};

export default EventFormSlideOver;