import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { useSearchParams, useNavigate } from "react-router-dom";

import DynamicFormGroups from "@/components/forms/DynamicFormGroups";
import { StackedSelectInputGroup } from "@/components/forms/Select";
import { Title } from "@/components/elements/typography/Title";
import { StackedInputGroup } from "@/components/forms/Input";

import { useReservationForm } from "../hooks/useReservationForm";
import { GuestInformationGuestForm } from "./GuestInformationGuestForm";
import { BaseSlideOver } from "@/components/slideover/BaseSlideOver";

const blankGuestForm = {
  firstName: "",
  firstNameFurigana: "",
  lastName: "",
  lastNameFurigana: "",
  alternateName: "",
  kanjiName: null,
  email: "",
  phone: "",
  gender: "",
  age: null,
  country: "",
  nationality: "",
  province: "",
  dob: null,
  address1: "",
  address2: "",
  zipcode: "",
  optOutEmail: false,
  isRepresentative: false,
  ageGroup: "",
  city: "",
};

const ReservationFormSlideOver: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initialCheckInDate = searchParams.get('checkInDate') || undefined;
  const initialRoomTypeId = searchParams.get('roomTypeIdToInsert') ? Number(searchParams.get('roomTypeIdToInsert')) : undefined;
  const initialRoomId = searchParams.get('roomIdToInsert') ? Number(searchParams.get('roomIdToInsert')) : undefined;

  const { methods, roomTypeOptions, onSubmit } = useReservationForm({
    checkInDate: initialCheckInDate,
    roomTypeId: initialRoomTypeId,
    roomId: initialRoomId,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  return (
    <FormProvider {...methods}>
      <BaseSlideOver
        title={t`Create New Reservation`}
        onSubmit={methods.handleSubmit(onSubmit)}
        open={open}
        onClose={() => setOpen(false)}
        afterLeave={() => navigate('..')}
      >
        <>
          <div className="ml-[72px] mt-3">
            <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full">
              <div className="mx-4 grid gap-x-6 gap-y-8 grid-cols-2 pb-3">
                <StackedInputGroup
                  label={t`Check In Date`}
                  name="checkInDate"
                  type="date"
                  required
                  options={{ required: t`Please select check in date` }}
                />
                <StackedInputGroup
                  label={t`Check Out Date`}
                  name="checkOutDate"
                  type="date"
                  required
                  options={{ required: t`Please select check out date` }}
                />
                <StackedInputGroup
                  label={t`Num of Adults`}
                  name="adults"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Num of Children`}
                  name="children"
                  type="number"
                />
                <StackedSelectInputGroup
                  label={t`Room Type`}
                  selectProps={{
                    name: "roomTypeId",
                    options: roomTypeOptions,
                  }}
                  required
                  options={{ required: t`Please select room type` }}
                />

                <StackedInputGroup
                  label={t`Rooms`}
                  name="rooms"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Booking Price`}
                  name="bookingPrice"
                  type="number"
                />
                <StackedInputGroup
                  label={t`Points Used`}
                  name="pointsUsed"
                  type="number"
                />
              </div>
            </div>
            <Title level={5} className="mt-3">
              <Trans>Guests</Trans>
            </Title>
          </div>
          <DynamicFormGroups
            name="guests"
            FormComponent={GuestInformationGuestForm}
            formMethods={methods}
            blankForm={blankGuestForm}
            addLabel={<UserPlusIcon className="h-5 w-5 text-gray-600" />}
            extraForms={1}
          />
        </>
      </BaseSlideOver>
    </FormProvider>
  );
};

export default ReservationFormSlideOver;
