import { ControllerRenderProps, useFormContext } from "react-hook-form";
import { ChangeEvent } from "react";

type CheckboxProps = {
  label: string;
  checked?: boolean;
  defaultValue?: boolean;
  description?: string;
  className?: string;
  onChange?: (value: string | number | any) => void;
  field?: ControllerRenderProps<any, any>;
};

type BaseCheckboxProps = {
  label: string;
  description?: string;
  className?: string;
};

type FormCheckboxProps = BaseCheckboxProps & {
  name: string;
  options?: any;
};


export function Checkbox({
  label,
  onChange,
  checked,
  description,
  className,
  field,
  defaultValue,
}: CheckboxProps) {
  // Pass either checked, onChange or field
  const isChecked = checked || field?.value;
  const handleChange = field
    ? (e: ChangeEvent<HTMLInputElement>) => field.onChange(e.target.checked)
    : onChange;

  return (
    <fieldset className={className}>
      <div className="space-y-5 mb-4">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              defaultChecked={defaultValue}
              checked={isChecked}
              aria-describedby="comments-description"
              name={label}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
              onChange={handleChange}
            />
          </div>

          <div className="ml-4 leading-6">
            <label htmlFor={label} className="font-medium text-gray-900">
              {label}
            </label>{" "}
            <span id="comments-description" className="text-gray-500">
              <span className="sr-only">{label} </span>
              {description}
            </span>
          </div>
        </div>
      </div>
    </fieldset>
  );
}


export function FormCheckbox({
  label,
  name,
  description,
  className,
  options,
}: FormCheckboxProps) {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name]?.message;

  return (
    <CheckboxLayout
      label={label}
      description={description}
      className={className}
    >
      <input
        {...register(name, options)}
        aria-describedby={`${label}-description`}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
      />
    </CheckboxLayout>
  );
}

function CheckboxLayout({
  label,
  description,
  className,
  children,
  error,
}: BaseCheckboxProps & { children: React.ReactNode; error?: string }) {
  return (
    <fieldset className={className}>
      <div className="space-y-5 mb-4">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            {children}
          </div>
          <div className="ml-4 text-sm leading-6">
            <label htmlFor={label} className="font-medium text-gray-900">
              {label}
            </label>{" "}
            {description && (
              <span id={`${label}-description`} className="text-gray-500">
                <span className="sr-only">{label} </span>
                {description}
              </span>
            )}
          </div>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600" id={`${label}-error`}>
            {error}
          </p>
        )}
      </div>
    </fieldset>
  );
}
