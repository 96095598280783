import React, { useState, useRef, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { ChevronDownIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { BedType } from '@/constants/common/bedTypes';
import { RoomStatus } from '@/constants/common/rooms';
import { Checkbox } from '@/components/forms/Checkbox';
import { VisibilityToggle, VisibilityOption } from './VisibilityToggle';
import { Button } from '@/components/elements/Button';

export interface FilterOption {
  id: string | number;
  name: string;
}

export interface Filters {
  roomTypes: FilterOption['id'][];
  bedTypes: FilterOption['id'][];
  roomStatuses: FilterOption['id'][];
  visibility: VisibilityOption;
}

interface RoomCalendarFilterProps {
  roomTypes: FilterOption[];
  initialFilters: Filters;
  onFilterChange: (filters: Filters) => void;
}

export const RoomCalendarFilter: React.FC<RoomCalendarFilterProps> = ({
  roomTypes,
  initialFilters,
  onFilterChange,
}) => {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [openMenu, setOpenMenu] = useState<keyof Filters | null>(null);
  const dropdownRefs = useRef<{[key in keyof Filters]: HTMLDivElement | null}>({
    roomTypes: null,
    bedTypes: null,
    roomStatuses: null,
    visibility: null,
  });

  const bedTypes: FilterOption[] = Object.values(BedType).map(type => ({ id: type, name: type }));
  const roomStatuses: FilterOption[] = [
    { id: RoomStatus.Clean, name: t`Clean` },
    { id: RoomStatus.Dirty, name: t`Dirty` },
    { id: RoomStatus.PendingInspection, name: t`Clean, but pending inspection` },
  ];

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMenu && !dropdownRefs.current[openMenu]?.contains(event.target as Node)) {
        setOpenMenu(null);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [openMenu]);

  const handleFilterChange = (filterType: keyof Omit<Filters, 'visibility'>, optionId: FilterOption['id']) => {
    const updatedFilter = filters[filterType].includes(optionId)
      ? filters[filterType].filter(id => id !== optionId)
      : [...filters[filterType], optionId];
    
    const newFilters = {
      ...filters,
      [filterType]: updatedFilter,
    };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleVisibilityChange = (visibility: VisibilityOption) => {
    const newFilters = {
      ...filters,
      visibility,
    };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleClearAll = () => {
    const clearedFilters: Filters = {
      roomTypes: [],
      bedTypes: [],
      roomStatuses: [],
      visibility: 'both',
    };
    setFilters(clearedFilters);
    onFilterChange(clearedFilters);
  };

  const handleClearFilter = (filterType: keyof Omit<Filters, 'visibility'>, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the dropdown from opening
    const newFilters = {
      ...filters,
      [filterType]: [],
    };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const FilterDropdown: React.FC<{
    label: string;
    options: FilterOption[];
    filterType: keyof Omit<Filters, 'visibility'>;
  }> = ({ label, options, filterType }) => {
    const toggleMenu = () => {
      setOpenMenu(openMenu === filterType ? null : filterType);
    };

    const isActive = filters[filterType].length > 0;

    return (
      <div className="relative inline-block text-left" ref={el => dropdownRefs.current[filterType] = el}>
        <div>
          <button
            onClick={toggleMenu}
            className={`inline-flex w-full justify-center items-center rounded-md border ${
              isActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white'
            } px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}
          >
            <span className="mr-1">{label}</span>
            {isActive && (
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-1 px-1.5 py-0.5 rounded">
                {filters[filterType].length}
              </span>
            )}
            <ChevronDownIcon className="-mr-1 ml-1 h-4 w-4" aria-hidden="true" />
          </button>
        </div>

        {openMenu === filterType && (
          <div className="absolute left-0 z-40 mt-1 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {isActive && (
                <button
                  className="text-gray-700 block w-full px-3 py-1 text-left text-xs hover:bg-gray-100"
                  onClick={(e) => handleClearFilter(filterType, e)}
                >
                  <Trans>Clear filters</Trans>
                </button>
              )}
              {options.map((option) => (
                <div key={option.id} className="px-3 py-1 hover:bg-gray-100 text-xs">
                  <Checkbox
                    label={option.name}
                    checked={filters[filterType].includes(option.id)}
                    onChange={() => handleFilterChange(filterType, option.id)}
                    className="text-xs"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const getFilterName = (filterType: keyof Omit<Filters, 'visibility'>, filterId: string | number) => {
    switch (filterType) {
      case 'roomTypes':
        return roomTypes.find(rt => rt.id === filterId)?.name || filterId;
      case 'bedTypes':
        return filterId;
      case 'roomStatuses':
        switch (filterId) {
          case RoomStatus.Clean:
            return <Trans>Clean</Trans>;
          case RoomStatus.Dirty:
            return <Trans>Dirty</Trans>;
          case RoomStatus.PendingInspection:
            return <Trans>Clean, but pending inspection</Trans>;
          default:
            return filterId;
        }
      default:
        return filterId;
    }
  };

  const getFilterColor = (filterType: keyof Omit<Filters, 'visibility'>) => {
    switch (filterType) {
      case 'roomTypes':
        return 'bg-blue-100 text-blue-800';
      case 'bedTypes':
        return 'bg-yellow-100 text-yellow-800';
      case 'roomStatuses':
        return 'bg-orange-100 text-orange-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };


  const activeFilters = Object.entries(filters).flatMap(([filterType, filterValues]) => 
    filterType !== 'visibility' ? filterValues.map((value: string) => ({
      type: filterType as keyof Omit<Filters, 'visibility'>,
      id: value,
      name: getFilterName(filterType as keyof Omit<Filters, 'visibility'>, value),
      color: getFilterColor(filterType as keyof Omit<Filters, 'visibility'>),
    })) : []
  );

  const isAnyFilterActive = activeFilters.length > 0;

  return (
    <div className="mb-2 bg-gray-50 rounded-lg">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center space-x-1 text-xs">
          <span className="font-medium text-gray-700">Filters:</span>
          <FilterDropdown label={t`Room Category`} options={roomTypes} filterType="roomTypes" />
          <FilterDropdown label={t`Bed Type`} options={bedTypes} filterType="bedTypes" />
          <FilterDropdown label={t`Room Status`} options={roomStatuses} filterType="roomStatuses" />
        </div>
        <div className="flex items-center space-x-1">
          <VisibilityToggle
            selected={filters.visibility}
            onChange={handleVisibilityChange}
          />
        </div>
      </div>
      {isAnyFilterActive && (
        <div className="px-2 py-1 flex items-center space-x-1 overflow-x-auto">
          {activeFilters.map((filter) => (
            <span
              key={`${filter.type}-${filter.id}`}
              className={`inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium ${filter.color}`}
            >
              {filter.name}
              <button
                type="button"
                className="ml-0.5 inline-flex h-3 w-3 flex-shrink-0 items-center justify-center rounded-full text-current hover:bg-opacity-75 focus:outline-none"
                onClick={() => handleFilterChange(filter.type, filter.id)}
              >
                <span className="sr-only"><Trans>Remove filter for {filter.name}</Trans></span>
                <XCircleIcon className="h-2 w-2" aria-hidden="true" />
              </button>
            </span>
          ))}
          <button
            onClick={handleClearAll}
            className="inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-800 hover:bg-red-200"
          >
            {t`Clear All`}
            <XCircleIcon className="ml-0.5 h-2 w-2" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
};
