import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGlobalStore } from '@/store/globalStore';
import { useCommonService } from '@/services/useCommonService';
import { useReservationService } from '@/services/useReservationService';
import { useRoomService } from '@/services/useRoomService';
import { RoomStatus } from '@/constants/common/rooms';
import { ReservationDetailDTO } from '@/types/reservation.dto';

export function useReservationDetailHeader(reservation: ReservationDetailDTO) {
  const params = useParams<{ propertyId: string; reservationId: string }>();
  const { getReservationStatuses } = useCommonService();
  const { getReservation, updateReservation } = useReservationService(params.propertyId as string);
  const { updateRoom } = useRoomService(params.propertyId as string);
  const reservationStatuses = useGlobalStore((state) => state.reservationStatuses);

  useEffect(() => {
    if (reservationStatuses.length === 0) getReservationStatuses();
  }, [reservationStatuses.length, getReservationStatuses]);

  const reservationStatusOptions = useMemo(
    () => reservationStatuses.map((status) => ({
      id: status.id,
      value: status.name,
      label: status.label,
    })),
    [reservationStatuses]
  );

  const roomStatusOptions = useMemo(
    () => Object.entries(RoomStatus).map(([key, value]) => ({
      value: value,
      label: key,
    })),
    []
  );

  const handleReservationStatusChange = useCallback(
    async (data: any) => {
      await updateReservation({
        id: reservation.id,
        reservationStatusId: data.id,
      });
    },
    [reservation.id, updateReservation]
  );

  const handleRoomStatusChange = useCallback(
    async (data: any) => {
      if (reservation.roomDetails) {
        await updateRoom(
          reservation.roomTypeDetails.id,
          reservation.roomDetails.id,
          { status: data.value }
        );
        await getReservation(params.reservationId);
      }
    },
    [reservation, updateRoom, getReservation, params.reservationId]
  );

  return {
    reservationStatusOptions,
    roomStatusOptions,
    handleReservationStatusChange,
    handleRoomStatusChange,
  };
}
