import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../../../utils";
import { SelectOption } from "./types";

export type SelectProps = {
  onChange: (selectedValue: any) => void;
  options: SelectOption[];
  inlineLabel: string;
  selected?: string | number | any;
  size?: "xs" | "sm" | "md" | "lg";
  disabled?: boolean;
  name?: string;
};

export function Select({
  onChange,
  options,
  inlineLabel,
  selected,
}: SelectProps) {
  return (
    <Listbox onChange={onChange}>
      <div className="relative mt-2">
        <ListboxButton className="relative w-60 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 text-sm leading-6">
          <span className="block truncate">{selected || inlineLabel}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions className="absolute z-10 mt-1 max-h-60 min-w-[15rem] overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            {options.map((option, idx) => (
              <ListboxOption
                key={idx}
                className={({ active }) =>
                  classNames(
                    active ? "bg-gray-600 text-white" : "text-gray-900",
                    "relative cursor-default select-none py-2 pl-3 pr-9"
                  )
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      )}
                    >
                      {option.label}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-gray-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}

