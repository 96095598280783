import { ControllerRenderProps } from "react-hook-form";
import { classNames } from "../../../utils";
import { useEffect, useState } from "react";

const baseClassNames =
  "block rounded-md border-0 py-1.5 focus:ring-2 focus:ring-inset text-sm leading-6";
const defaultColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600";
const disabledColorClassNames =
  "text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200";
type CheckboxWithInputProps = {
  label: string;
  field: ControllerRenderProps<any, any>;
  description?: string;
  className?: string;
};

export function CheckboxWithInput({
  label,
  field,
  description,
  className,
}: CheckboxWithInputProps) {
  const [checked, setChecked] = useState(false);
  let inputClassNames = classNames(baseClassNames, "w-full");
  if (!checked) {
    inputClassNames = classNames(inputClassNames, disabledColorClassNames);
  } else {
    inputClassNames = classNames(inputClassNames, defaultColorClassNames);
  }

  useEffect(() => {
    setChecked(field.value);
  }, [field.value]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  };
  return (
    <fieldset className={className}>
      <div className="space-y-5 mb-4">
        <div className="relative flex items-center">
          <div className="flex h-6 items-center">
            <input
              checked={checked}
              aria-describedby="comments-description"
              name={field.name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
              onChange={handleCheckboxChange}
            />
          </div>

          <div className="flex flex-col ml-4 w-full">
            <div className="text-sm leading-6">
              <label htmlFor={label} className="font-medium text-gray-900">
                {label}
              </label>{" "}
              <span id="comments-description" className="text-gray-500">
                <span className="sr-only">{label} </span>
                {description}
              </span>
            </div>
            <input
              disabled={!checked}
              type="text"
              className={inputClassNames}
              aria-label={`Input for ${label}`}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
}
