import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ReservationState, useReservationStore } from "@/store/reservationStore";
import { useReservationService } from "@/services/useReservationService";
import { Some } from "@/utils";
import { ContentLayout } from "@/components/layouts";
import { Title } from "@/components/elements/typography/Title";
import { Loading } from "@/components/elements/Loading";

import { ReservationDetailSidebar } from "../components/ReservationDetailSidebar";
import { GuestInformationSection } from "../components/GuestInformationSection";
import { ReservationDetailOptionTable } from "../components/ReservationDetailOptionTable";
import { ReservationMemoSection } from "../components/ReservationMemoSection";
import { ReservationDetailHeader } from "../components/ReservationDetailHeader";

export type ReservationRouteParam = {
  propertyId: string;
  reservationId: string;
};

export function ReservationDetailView() {
  const params = useParams<ReservationRouteParam>();
  const reservation = useReservationStore(
    (state: ReservationState) => state.reservation
  )

  const propertyId = params.propertyId ?? "";

  const { getReservation } = useReservationService(propertyId)

  useEffect(() => {
    if (params.reservationId) {
      getReservation(params.reservationId)
    }
  }, [params.reservationId]);

  return Some(reservation) ? (
    <ContentLayout
      sideColumn={
        <ReservationDetailSidebar
          reservation={reservation}
          propertyId={propertyId}
        />
      }
    >
      <ReservationDetailHeader reservation={reservation} />
      <Title level={4}>Reservation</Title>

      <GuestInformationSection reservation={reservation} />

      <Title className="card-title" level={4}>
        Options
      </Title>
      {/* @NOTES: We do not have a way */}
      <ReservationDetailOptionTable reservation={reservation} />
      <ReservationMemoSection reservation={reservation} />
    </ContentLayout>
  ) : (
    <Loading />
  )
};
