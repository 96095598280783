import { useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReservationInvoiceRouteParam } from "../routes/InvoiceManagementView";
import { useReservationService } from "@/services/useReservationService";
import { useReservationStore, ReservationState } from "@/store/reservationStore";
import { t } from "@lingui/macro";

const invoiceItems: any[] = [
  {
    id: 1,
    name: "Room Charge",
    option: { optionCategory: { name: "Accommodation" } },
    date: "2023-04-01",
    quantity: 2,
    price: 200,
    room: "101",
    isReducedTaxRate: false,
    tax: 10,
    amount: 400
  },
  {
    id: 2,
    name: "Breakfast",
    option: { optionCategory: { name: "Food & Beverage" } },
    date: "2023-04-02",
    quantity: 4,
    price: 60,
    room: "101",
    isReducedTaxRate: true,
    tax: 8,
    amount: 240
  },
  {
    id: 3,
    name: "Late Checkout",
    option: { optionCategory: { name: "Service" } },
    date: "2023-04-03",
    quantity: 1,
    price: 50,
    room: "101",
    isReducedTaxRate: false,
    tax: 10,
    amount: 50
  }
];

const transactionItems: any[] = [
  {
    id: 1,
    name: "Deposit",
    date: "2023-03-15",
    quantity: 1,
    amount: 100,
    description: "Reservation deposit",
    paymentType: "Credit Card",
    status: "Completed"
  },
  {
    id: 2,
    name: "Partial Payment",
    date: "2023-04-01",
    quantity: 1,
    amount: 150,
    description: "Partial payment on check-in",
    paymentType: "Cash",
    status: "Completed"
  },
  {
    id: 3,
    name: "Final Payment",
    date: "2023-04-03",
    quantity: 1,
    amount: 89.80,
    description: "Remaining balance on check-out",
    paymentType: "Credit Card",
    status: "Pending"
  }
];

export function useInvoiceManagement() {
  const navigate = useNavigate();
  const params = useParams<ReservationInvoiceRouteParam>();
  const propertyId = params.propertyId ?? "";
  
  const reservation = useReservationStore(
    (state: ReservationState) => state.reservation
  );
  const { getReservation } = useReservationService(propertyId);

  useEffect(() => {
    if (params.reservationId) {
      getReservation(params.reservationId);
    }
  }, [params.reservationId, getReservation]);

  const invoices = reservation?.invoices || [];
  // const invoices: any[] = [
  //   {
  //     id: 1,
  //     name: "Invoice 1",
  //     transactionItems,
  //     invoiceItems,
  //     totalAmountWithTax: 100,
  //     taxAmount: 10,
  //     totalAmountWithoutTax: 90,
  //     totalTransaction: 100,
  //     totalDue: 100
  //   },
  //   {
  //     id: 2,
  //     name: "Invoice 2",
  //     transactionItems,
  //     invoiceItems,
  //     totalAmountWithTax: 200,
  //     taxAmount: 20,
  //     totalAmountWithoutTax: 90,
  //     totalTransaction: 100,
  //     totalDue: 100
  //   }
  // ]
  
  const selectedInvoice = useMemo(() => {
    if (invoices.length === 0) return null;
    return invoices.find(
      (invoice) => invoice.id === parseInt(params.invoiceId!, 10)
    ) || invoices[0];
  }, [invoices, params.invoiceId]);

  useEffect(() => {
    if (reservation) {
      if (invoices.length > 0) {
        const invoiceId = params.invoiceId
          ? parseInt(params.invoiceId, 10)
          : null;
        const validInvoiceId = invoices.some(
          (invoice) => invoice.id === invoiceId
        );

        if (!validInvoiceId) {
          // Redirect to the first invoice if the current invoiceId is not valid
          navigate(
            `/properties/${propertyId}/reservations/${params.reservationId}/invoices/${invoices[0].id}`,
          );
        }
      } else if (params.invoiceId) {
        // If there are no invoices but an invoiceId is in the URL, redirect to the reservation detail
        navigate(
          `/properties/${propertyId}/reservations/${params.reservationId}`,
        );
      }
    }
  }, [params.invoiceId, invoices, propertyId, params.reservationId, reservation]);

  const tabs = invoices.length ? invoices.map((invoice) => ({
    name: invoice.description || `Invoice #${invoice.id}`,
    id: invoice.id + "",
    path: `/properties/${propertyId}/reservations/${params.reservationId}/invoices/${invoice.id}`,
  })) : []

  return {
    selectedInvoice,
    tabs,
    reservation
  }
}