import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useUserStore } from '../../store/userStore';
import { useAuthService } from '../../services/useAuthService';
import { Trans } from '@lingui/macro';

export const UserInfo: React.FC = () => {
  const user = useUserStore((state) => state.user);
  const { logoutUser } = useAuthService();

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    await logoutUser();
  };

  return (
    <div className="block flex-shrink-0 p-2">
      <div className="flex items-center">
        <div>
          <UserCircleIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700">{user?.fullName}</p>
          <span>
            <Link
              to="/settings/basic"
              className="text-xs font-medium text-gray-500 hover:text-gray-700"
            >
              <Trans>Settings</Trans>
            </Link>
            {" - "}
            <a
              href="#"
              onClick={handleLogout}
              className="text-xs font-medium text-gray-500 hover:text-gray-700"
            >
              <Trans>Logout</Trans>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

