import { AddReservationGuestDTO, UpdateReservationGuestDTO } from "@/types/guest.dto";
import { EngagementChannels } from "@/types/surveyResponse";

export type GuestInformationInputs = {
  guests: AddReservationGuestDTO[];
  engagementChannels: EngagementChannels;
  reservationGroupType: string;
};

export type GuestInformationSlideOverProps = {
  urlSuffix: string;
};

export function isUpdateReservationGuestDTO(
  obj: UpdateReservationGuestDTO | AddReservationGuestDTO
): obj is UpdateReservationGuestDTO {
  return (obj as UpdateReservationGuestDTO).id !== undefined;
}
