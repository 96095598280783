import { Select, SelectProps } from "./Select";

type SelectInputGroupProps = {
  label: string;
  selectProps: SelectProps;
  error?: string;
};

export function SelectInputGroup({
  label,
  selectProps,
  error,
}: SelectInputGroupProps) {
  return (
    <div className="grid grid-cols-3 gap-4 space-y-0 px-6 py-3">
      <div>
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-6 text-gray-900 mt-3"
        >
          {label}
        </label>
      </div>
      <div className="col-span-2">
        <Select {...selectProps} />
        {error && (
        <p className="mt-2 text-sm text-red-600" id={`${selectProps.name}-error`}>
          {error}
        </p>
      )}
      </div>
    </div>
  );
}

