import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Trans } from "@lingui/macro";
import { Button } from "../elements";

interface Column {
  key: string;
  title: string;
  sortable?: boolean;
}

interface Item {
  id: number;
  [key: string]: string | number | undefined | null;
}

interface TableProps {
  data: Item[];
  columns: Column[];
  onEdit: (item: Item) => void;
  onDelete?: (itemId: number) => void;
  onSort?: (columnKey: string, direction: "asc" | "desc") => void;
  currentSortColumn?: string;
  currentSortDirection?: "asc" | "desc";
}

export function Table({
  data,
  columns,
  onEdit,
  onDelete,
  onSort = () => {},
  currentSortColumn,
  currentSortDirection,
}: TableProps) {
  const handleSort = (columnKey: string) => {
    const newDirection =
      columnKey === currentSortColumn && currentSortDirection === "asc"
        ? "desc"
        : "asc";
    onSort(columnKey, newDirection);
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                column.sortable ? "cursor-pointer hover:bg-gray-100" : ""
              }`}
              onClick={() => column.sortable && handleSort(column.key)}
            >
              <div className="group inline-flex">
                {column.title}
                {column.sortable && currentSortColumn === column.key && (
                  <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                    {currentSortDirection === "asc" ? (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                )}
              </div>
            </th>
          ))}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <Trans>Actions</Trans>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.length === 0 ? (
          <tr>
            <td
              colSpan={columns.length + 1}
              className="px-6 py-4 whitespace-nowrap text-center text-gray-500"
            >
              <Trans>No data available.</Trans>
            </td>
          </tr>
        ) : (
          data.map((item) => (
            <tr key={item.id}>
              {columns.map((column) => (
                <td key={column.key} className="px-6 py-4 whitespace-nowrap">
                  {item[column.key]}
                </td>
              ))}
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <Button type="text" size="sm" onClick={() => onEdit(item)}>
                  <Trans>Edit</Trans>
                </Button>
                {onDelete && (
                  <Button type="text" onClick={() => onDelete(item.id)} size="sm">
                    <Trans>Delete</Trans>
                  </Button>
                )}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}
