import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { Trans } from '@lingui/macro';

interface CellActionMenuProps {
  onCreateEvent: () => void;
  onCreateReservation: () => void;
  onAssignReservation: () => void;
}

export const CellActionMenu: React.FC<CellActionMenuProps> = ({ onCreateEvent, onCreateReservation, onAssignReservation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleItemClick = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cellRef.current && !cellRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <div 
      ref={cellRef}
      className="relative w-full h-full group"
      onClick={toggleMenu}
    >
      <div 
        className="w-full h-full cursor-pointer transition-colors duration-200 ease-in-out group-hover:bg-gray-100"
        aria-haspopup="true"
        aria-expanded={isOpen}
      />

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div 
          className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1">
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onCreateEvent)}
            >
              <Trans>Create new event</Trans>
            </button>
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onCreateReservation)}
            >
              <Trans>Create new reservation</Trans>
            </button>
            <button
              className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
              onClick={() => handleItemClick(onAssignReservation)}
            >
              <Trans>Assign reservation</Trans>
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

