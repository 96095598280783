import "react-phone-number-input/style.css";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useFormContext } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import enCountryLabels from "react-phone-number-input/locale/en.json";
import jaCountryLabels from "react-phone-number-input/locale/ja.json";
import { useUserStore } from "../../../store/userStore";
import { StackedInputGroupProps } from "./types";

export function StackedPhoneInputGroup({
  name,
  label,
  helpText,
  className,
}: StackedInputGroupProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext<{ [key: string]: string }>();
  const user = useUserStore((state) => state.user);
  let labels = enCountryLabels;
  if (user?.locale === "ja") {
    labels = jaCountryLabels;
  }

  const error = errors[name]?.message;
  const showHelpText = helpText && !error;
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      <div className="relative mt-2">
        <PhoneInputWithCountry
          name={name}
          international
          numberInputProps={{
            className:
              "block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm leading-6 rounded-md shadow-sm",
          }}
          control={control}
          countrySelectProps={{ unicodeFlags: true }}
          labels={labels}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}
