import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { SlideOver, SlideOverProps } from '@/components/slideover/SlideOver';
import { useAssignReservation } from '../hooks/useAssignReservation';
import { RoomDTO } from '@/types/room.dto';
import { ReservationListItemDTO } from '@/types/reservation.dto';
import { Input } from "@/components/forms/Input/Input";

interface AssignReservationSlideOverProps {
  date: string;
  room: RoomDTO;
  currentAssignedReservationId?: number;
}

const AssignReservationSlideOver: React.FC<AssignReservationSlideOverProps> = ({
  date,
  room,
  currentAssignedReservationId,
}) => {
  const [open, setOpen] = useState(true);
  const onClose = () => setOpen(false);
  const {
    reservations,
    onSubmit,
    selectedReservationId,
    setSelectedReservationId,
    currentAssignedReservationId: currentAssigned,
  } = useAssignReservation(onClose, date, room, currentAssignedReservationId);
  const methods = useForm<{ reservationId: number }>();

  // @NOTE: Reservation table search box
  const [searchTerm, setSearchTerm] = useState<string>('');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredReservations = reservations.filter(reservation => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (reservation.temairazuOTABookingId?.toLowerCase().includes(searchTermLower) ?? false) ||
      (reservation.guestName?.toLowerCase().includes(searchTermLower) ?? false)
    );
  });

  const handleSelectionToggle = (reservationId: number) => {
    setSelectedReservationId(prevId => prevId === reservationId ? null : reservationId);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedReservationId) {
      onSubmit({ reservationId: selectedReservationId });
    }
  };

  const renderDetails = () => (
    <div className="border-b border-gray-200 pb-4 pl-6 pt-4 text-sm">
      <p>
        <Trans><strong>Selected Date:</strong> {date}</Trans>
      </p>
      <p>
        <Trans><strong>Room Type:</strong> {room.roomTypeName}</Trans>
      </p>
      <p>
        <Trans><strong>Room Number:</strong> {room.name}</Trans>
      </p>
      <p>
        <Trans><strong>Bed Type:</strong> {room.bedType}</Trans>
      </p>
      <p>
        <Trans><strong>Usage Rate:</strong> {room.roomUsageRate}%</Trans>
      </p>
      <p>
        <Trans><strong>Status:</strong> {room.status}</Trans>
      </p>
    </div>
  );

  const renderSearchBox = () => (
    <div className="px-4">
      <Input
        name="reservation-search"
        type="text"
        placeholder={t`Search reservations...`}
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </div>
  );


  const renderReservationTable = () => (
    <div className="mx-4 my-6 overflow-hidden border border-gray-200 rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Reservation #</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Guest Name</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans># of Guests</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Check-in Date</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Check-out Date</Trans>
            </th>
            <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <Trans>Status</Trans>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredReservations.map((reservation: ReservationListItemDTO) => (
            <tr
              key={reservation.id}
              onClick={() => handleSelectionToggle(reservation.id)}
              className={`cursor-pointer hover:bg-gray-50 ${
                selectedReservationId === reservation.id
                  ? 'bg-blue-50'
                  : reservation.id === currentAssigned
                    ? 'bg-yellow-50'
                    : ''
              }`}
            >
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.bookingId}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-900">{reservation.guestName}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.numberOfGuests}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.checkInDate}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">{reservation.checkOutDate}</td>
              <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-500">
                {reservation.id === currentAssigned ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <Trans>Current</Trans>
                  </span>
                ) : (
                  <input
                    type="radio"
                    checked={selectedReservationId === reservation.id}
                    onChange={() => handleSelectionToggle(reservation.id)}
                    onClick={(e) => e.stopPropagation()}
                    className="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded-full"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <SlideOver
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={currentAssignedReservationId ? t`Reassign Reservation` : t`Assign Reservation`}
        saveButtonName={t`Confirm`}
        content={
          <div className="space-y-6">
            {renderDetails()}
            {renderSearchBox()}
            {renderReservationTable()}
          </div>
        }
      />
    </FormProvider>
  );
};

export default AssignReservationSlideOver;
