import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/elements";
import Tabs from "@/components/elements/Tabs";
import { useReservationService } from "@/services/useReservationService";
import {
  useReservationStore,
  ReservationState,
} from "@/store/reservationStore";
import { Loading } from "@/components/elements/Loading";
import { Table } from "@/components/datadisplay/Table";
import { t } from "@lingui/macro";
import { useInvoiceManagement } from "../hooks/useInvoiceManagement";
import PlaceholderSlideOver from "@/features/reservation/components/PlaceholderSlideOver";
import InvoiceItemSlideOver from "../components/InvoiceItemSlideOver";
import { InvoiceItemDTO } from "@/types/invoiceItem.dto";
import { useUrlSlideOver } from "@/hooks/useUrlSlideOver";

export type ReservationInvoiceRouteParam = {
  propertyId: string;
  reservationId: string;
  invoiceId: string;
  invoiceItemId: string;
};

export function InvoiceManagementView() {
  const navigate = useNavigate();
  const params = useParams<ReservationInvoiceRouteParam>();

  const propertyId = params.propertyId ?? "";

  const { selectedInvoice, tabs, reservation } = useInvoiceManagement();

  const handleGoBack = () => {
    navigate(`/properties/${propertyId}/reservations/${params.reservationId}`);
  };

  if (!reservation) return <Loading />;

  return (
    <div className="p-6">
      <div className="flex items-center space-x-4">
        <Button onClick={handleGoBack} type="link">
          Reservation Detail
        </Button>
        <span>/</span>
        <span className="text-sm">Invoice Management</span>
      </div>

      <Tabs
        className="mt-2"
        tabs={tabs}
        defaultActiveTab={selectedInvoice ? selectedInvoice.id + "" : "0"}
        extraElement={
          <Button
            type="primary"
            size="md"
            onClick={() => navigate(`./add-invoice`)}
          >
            {t`+ Add Invoice`}
          </Button>
        }
      />
      <div>
        <div className="mt-2">Invoice Items</div>
        <div className="mt-2 min-h-80">
          <Table
            data={
              selectedInvoice?.items?.map((item: any) => ({
                id: item.id,
                name: item.name,
                category: item?.option?.optionCategory?.name || "Manual",
                date: item.date,
                quantity: item.quantity,
                price: item.unitPrice,
                room: item.room,
                // isReducedTaxRate: item.isReducedTaxRate ? "※" : "",
                tax: item.tax?.value + "%",
                amount: item.totalAmount,
              })) || []
            }
            columns={[
              {
                key: "date",
                title: t`Date`,
              },
              {
                key: "name",
                title: t`Description`,
              },
              {
                key: "room",
                title: t`Room`,
              },
              // {
              //   key: "isReducedTaxRate",
              //   title: t`Reduced Tax Rate`,
              // },
              {
                key: "quantity",
                title: t`Quantity`,
              },
              {
                key: "price",
                title: t`Unit Price (incl. tax)`,
              },
              {
                key: "tax",
                title: t`Tax %`,
              },
              {
                key: "amount",
                title: t`Amount (incl. tax)`,
              },
            ]}
            onEdit={(item) => { navigate(`./edit-invoice-item/${item.id}`) }}
          />
          <div>※ this item is subject to the reduced tax rate (8%)</div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div>Transactions / Deposits</div>
          <div className="mt-2 min-h-60">
            <Table
              data={
                selectedInvoice?.transactions?.map((item: any) => ({
                  id: item.id,
                  description: item.description,
                  date: item.date,
                  paymentType: item.paymentType,
                  status: item.status,
                  amount: item.amount,
                })) || []
              }
              columns={[
                {
                  key: "date",
                  title: t`Date`,
                },
                {
                  key: "description",
                  title: t`Description`,
                },
                {
                  key: "paymentType",
                  title: t`Payment Type`,
                },
                {
                  key: "status",
                  title: t`Status`,
                },
                {
                  key: "amount",
                  title: t`Amount`,
                },
              ]}
              onEdit={(item) => console.log(item)}
            />
          </div>
        </div>
        <div className="grid grid-cols-subgrid col-span-4 text-right place-content-end col-start-9 bg-gray-50 rounded-md p-4">
          <div className="col-span-3">合計金額（税込）/ Total (incl. tax):</div>
          <div className="col-span-1">
            {selectedInvoice?.totalAmount}
          </div>
          <div className="col-span-3">消費税額 / Tax:</div>
          <div className="col-span-1">{selectedInvoice?.taxAmount}</div>
          <div className="col-span-3">合計（税抜き） / Total (excl. tax):</div>
          <div className="col-span-1">
            {selectedInvoice ? (selectedInvoice.totalAmount - selectedInvoice.taxAmount) : 0}
          </div>
          <div className="col-span-3">
            合計（支払い済み）/ Transaction Total:
          </div>
          <div className="col-span-1">{selectedInvoice?.totalTransaction}</div>
          <div className="col-span-4 border grid grid-cols-4 m-4 p-2 font-semibold">
            <div className="col-span-3">合計（未払金）/ Total Due: </div>
            <div>{selectedInvoice?.totalDue}</div>
          </div>
        </div>
      </div>
      {selectedInvoice && (
        <div className="mt-2">
          <div className="flex flex-row-reverse space-x-5 space-x-reverse">
            <Button
              type="default"
              size="md"
              onClick={() => navigate(`./add-payment-transaction`)}
            >
              {t`Add Payment Transaction`}
            </Button>
            <Button
              type="default"
              size="md"
              onClick={() => navigate(`./add-invoice-item`)}
            >
              {t`Add Invoice Item`}
            </Button>
            <Button>Preview Print</Button>
            <Button disabled>View Receipt</Button>
          </div>
        </div>
      )}

      <Outlet/>
    </div>
  );
}
