import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { t } from "@lingui/macro";

import { useRoomInventoryCalendarService } from "@/services/useRoomInventoryCalendarService";
import { GlobalState, useGlobalStore } from "@/store/globalStore";
import { useNotificationStore } from "@/store/notificationStore";
import { useRoomInventoryStore } from "@/store/roomInventoryStore";

export const defaultRoomInventoryCalendarQueryParam = {
  startDate: new Date().toISOString().split('T')[0], // Today's date in YYYY-MM-DD format
};

export const OVERBOOKED = t`Overbooked`;

export const useRoomInventoryCalendar = () => {
  const params = useParams<{ propertyId: string }>();
  const addNotification = useNotificationStore((state) => state.addNotification);
  const [searchParams, setSearchParams] = useSearchParams();
  const inventory = useRoomInventoryStore(state => state.inventory);
  const summary = useRoomInventoryStore(state => state.summary);
  const roomInventoryDetail = useRoomInventoryStore(state => state.roomInventoryDetail);
  const setRoomInventoryDetail = useRoomInventoryStore(state => state.setRoomInventoryDetail);
  const isDetailLoading = useRoomInventoryStore(state => state.isDetailLoading);
  const isInventoryLoading = useRoomInventoryStore(state => state.isInventoryLoading);

  const loading = useGlobalStore((state: GlobalState) => state.loading);
  const { 
    getRoomInventoryCalendar,
    getRoomInventoryDetail,
    initializeInventory,
    extendInventory,
    checkRoomTypeAvailability
  } = useRoomInventoryCalendarService(
    params.propertyId as string
  );

  const queryParams = searchParams.toString();
  const startDate = searchParams.get('startDate');

  useEffect(() => {
    if (startDate) {
      getRoomInventoryCalendar({ startDate });
    } else {
      setSearchParams(queryString.stringify(defaultRoomInventoryCalendarQueryParam));
    }
  }, [startDate]); // Only depend on startDate changes

  const updateFilters = (newFilters: Partial<typeof defaultRoomInventoryCalendarQueryParam>) => {
    const currentFilters = queryString.parse(queryParams);
    const updatedFilters = { ...currentFilters, ...newFilters };
    setSearchParams(queryString.stringify(updatedFilters));
  };

  const handleGetRoomInventoryDetail = async (roomInventoryId: number) => {
    try {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("inventoryId", roomInventoryId.toString());
      setSearchParams(newParams);
      await getRoomInventoryDetail(roomInventoryId);
    } catch (error) {
      addNotification({
        title: t`Error`,
        type: 'error',
        message: t`Failed to fetch room inventory detail`,
      });
    }
  };

  const clearRoomInventoryDetail = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete("inventoryId");
    setSearchParams(newParams);
    setRoomInventoryDetail(undefined);
  };

  return {
    loading,
    updateFilters,
    getRoomInventoryDetail: handleGetRoomInventoryDetail,
    roomInventoryDetail,
    clearRoomInventoryDetail,
    searchParams,
    inventory,
    summary,
    isDetailLoading,
    isInventoryLoading,
  };
};
