import { Controller, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react'
import { t } from '@lingui/macro';

import { useOptionStore } from '../../../store/optionStore';
import { useOptionService } from '../../../services/useOptionService';
import { SelectInputGroup } from '../../../components/forms/Select';
import { useTaxStore } from '../../../store/taxStore';
import { Switch } from '../../../components/forms/Switch';
import { InputGroup } from '../../../components/forms/Input';
import { sanitizeNumberField, Some } from '../../../utils';
import { CreateInvoiceItemDTO, InvoiceItemDTO } from '../../../../types/invoiceItem.dto';
import { PriceSummary } from '../../../components/forms/PriceSummary';

type Inputs = {
  name: string;
  price: number;
  taxId?: number; 
  optionId?: number;
  quantity: number;
  cost?: number;
  date?: string;
}

type Props = {
  invoiceItem?: InvoiceItemDTO | null;
  propertyId: string;
  methods: UseFormReturn<any>;
};

const InvoiceItemForm: React.FC<Props> = ({ invoiceItem, propertyId, methods }) => {
  const isAdding = !invoiceItem;

  const options = useOptionStore(state => state.options);
  const option = useOptionStore(state => state.option);
  const setOption = useOptionStore(state => state.setOption);
  const setOptionCategory = useOptionStore(state => state.setOptionCategory);

  const taxes = useTaxStore(state => state.taxes);
  const { getOptions } = useOptionService(propertyId);

  useEffect(() => {
    if (!options.length) {
      getOptions();
    }
    if (invoiceItem && invoiceItem.option && !option) {
      setOption(invoiceItem.option);
    }
  }, []);

  const [overrideCost, setOverrideCost] = useState(false);
  const [overridePrice, setOverridePrice] = useState(false);

  const defaultValues: Inputs = {
    name: '',
    price: 0,
    taxId: undefined,
    optionId: undefined,
    quantity: 0,
    cost: 0,
  };
  
  const initialValues: Inputs = invoiceItem ? {
    name: invoiceItem.name,
    price: invoiceItem.unitPrice,
    taxId: invoiceItem.tax?.id,
    optionId: invoiceItem.option?.id,
    quantity: invoiceItem.quantity,
    cost: invoiceItem.cost,
  } : defaultValues;

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
      if (option && (!invoiceItem || (invoiceItem.option && option.id !== invoiceItem.option.id))) {
        setValue("taxId", option.tax?.id);
        setValue("price", option.price);
        setValue("name", option.name);
        setValue("optionId", option.id);
      }
  }, [option]);

  // Summary calculation
  const watchQuantity = watch("quantity");
  const selectedTaxId = watch("taxId");
  const price = watch("price");

  const selectedTaxValue = taxes.find(tax => tax.id === selectedTaxId)?.value ?? 0;
  const quantity = sanitizeNumberField(watchQuantity);

  const pricePerItem = price ?? 0;
  const subTotal = quantity * pricePerItem;
  const totalPrice = Math.floor(subTotal * (1 + selectedTaxValue));
  const totalTax = totalPrice - subTotal;

  return (
    <div className="space-y-0 divide-y divide-gray-200 py-0">
      <Controller
        control={control}
        name="date"
        rules={{
          required: isAdding ? t`Date is required` : false,
        }}
        render={({ field }) => (
          <InputGroup
            label={t`Date`}
            inputProps={{
              name: "date",
              type: "date",
              defaultValue: isAdding ? "" : invoiceItem.date,
              disabled: !isAdding,
              value: field.value,
              onChange: (value) => {
                field.onChange(value)
              }
            }}
            error={errors.date?.message as string}
          />
        )}
      />

      <Controller 
        control={control}
        name={"optionId"}
        defaultValue={initialValues.optionId}
        render={({ field }) => {
          return (
            <SelectInputGroup
              selectProps={{
                options: options.map(option => {
                  return {
                    value: option.id,
                    label: option.name,
                  }
                }),
                inlineLabel: t`Select an option`,
                selected: option && option.name,
                onChange: async (data) => {
                  field.onChange(data.value)
                  const selectedOption = options.find(option => option.id === data.value);
                  setOption(selectedOption);
                }
              }}
              label={t`Options`}
              error={errors.optionId?.message as string}
            />
          )
        }}
      />

      <Controller
        control={control}
        name="name"
        rules={{
          required: t`Name is required`,
        }}
        render={({ field }) => (
          <InputGroup
            label={t`Name`}
            inputProps={{
              name: "name",
              value: field.value,
              onChange: (value) => {
                field.onChange(value)
              }
            }}
            error={errors.name?.message as string}
          />
        )}
      />

      <Controller
        control={control}
        name="taxId"
        defaultValue={option?.tax?.id}
        render={({ field }) => {
          const currentTaxId = option?.tax?.id
          const selectedTax = taxes.find(tax => tax.id === field.value)?.name;
          const currentTax = taxes.find(tax => tax.id === currentTaxId)?.name;

          return (
            <SelectInputGroup
              selectProps={{
                options: taxes.map(tax=> {
                  return {
                    value: tax.id,
                    label: tax.name,
                  }
                }),
                name: "taxId",
                inlineLabel: t`Select tax`,
                selected: selectedTax || currentTax,
                onChange: async (data) => {
                  field.onChange(data.value)
                }
              }}
              label={t`Tax`}
              error={errors.taxId?.message as string}
            />
          )
        }}
      />

      <div>
        <Switch
          label={t`Override price`}
          onChange={(value) => {
            setOverridePrice(value);
          }}
        />

        <Controller
          control={control}
          name="price"
          defaultValue={initialValues.price}
          rules={{
            required: t`Price is required`,
            min: { value: 0, message: t`Price must be 0 or greater` }
          }}
          render={({ field }) => (
            <InputGroup
              label={t`Price`}
              inputProps={{
                type: "number",
                name: "price",
                value: field.value,
                disabled: !overridePrice,
                onChange: (e) => {
                  field.onChange(Number(e.target.value));
                }
              }}
              error={errors.price?.message as string}
            />
          )}
        />

        <Controller
          control={control}
          name="quantity"
          defaultValue={initialValues.quantity}
          rules={{
            required: t`Quantity is required`,
            min: { value: 1, message: t`Quantity must be 1 or greater` },
            validate: (value) => Number.isInteger(Number(value)) || t`Quantity must be a whole number`
          }}
          render={({ field }) => (
            <InputGroup
              label={t`Quantity`}
              inputProps={{
                type: "number",
                name: "quantity",
                value: field.value,
                onChange: (e) => {
                  field.onChange(Number(e.target.value));
                }
              }}
              error={errors.quantity?.message as string}
            />
          )}
        />
      </div>

      <div>
        <Switch
          label={t`Override default cost`}
          onChange={(value) => {
            setOverrideCost(value);
          }}
        />

        <Controller
          control={control}
          name="cost"
          defaultValue={option?.cost || 0}
          rules={{
            min: { value: 0, message: t`Cost must be 0 or greater` }
          }}
          render={({ field }) => (
            <InputGroup
              label={t`Cost`}
              inputProps={{
                type: "number",
                name: "cost",
                defaultValue: field.value,
                disabled: !overrideCost,
                onChange: (value) => {
                  field.onChange(value)
                }
              }}
              error={errors.cost?.message as string}
            />
          )}
        />
      </div>

      <PriceSummary
        pricePerItem={pricePerItem}
        subTotal={subTotal}
        totalTax={totalTax}
        totalPrice={totalPrice}
        taxValue={selectedTaxValue ? selectedTaxValue * 100 : undefined}
      />
    </div>
  );
}

export default InvoiceItemForm;