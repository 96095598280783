import { RegisterOptions, useFormContext } from "react-hook-form";
import { classNames, getNestedValue } from "@/utils";
import { defaultColorClassNames, disabledColorClassNames, errorColorClassNames } from "./Input";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

type TextAreaProps = {
  name: string;
  value?: string;
  label: string;
  required?: boolean;
  rows?: number;
  fullWidth?: boolean;
  helpText?: string;
  options?: RegisterOptions;
  className?: string;
}

export function TextAreaInputGroup({
  name,
  value,
  label,
  helpText,
  required = false,
  rows = 4,
  fullWidth = true,
  options,
  className,
}: TextAreaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const error = getNestedValue(errors, name)?.message;
  const showHelpText = helpText && !error;
  const baseClassName = `block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm ${fullWidth ? "w-full" : "w-3/6"}`

  let activeClassNames = classNames(baseClassName, "w-full");
  if (options?.disabled) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }
  return (
    <div className={className}>
      <label
         htmlFor={name}
         className="block text-sm font-medium leading-6 text-gray-900"
        >
        {`${label}${required ? "*" : ""}`}
      </label>
      <div className="mt-2">
        <textarea
          {...register(name, options)}
          required={required}
          rows={rows}
          className={`block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 text-sm ${fullWidth ? "w-full" : "w-3/6"}`}
          value={value}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}

