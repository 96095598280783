import { t, Trans } from "@lingui/macro";
import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import {
  StackedCountryDropdown,
  StackedProvinceDropdown,
} from "@/components/forms/Select";
import {
  StackedInputGroup,
  StackedPhoneInputGroup,
} from "@/components/forms/Input";
import {
  StackedSelectInputGroup,
} from "@/components/forms/Select";
import { Checkbox } from "@/components/forms/Checkbox";
import { toValueLabelList } from "@/utils";
import { AgeGroup } from "@/constants/common/ageGroups";
import { SelectOption } from "@/components/forms/Select/types";

const genderOptions: SelectOption[] = [
  { value: "Male", label: t`Male` },
  { value: "Female", label: t`Female` },
];

export const GuestInformationGuestForm = ({ control, index }: any) => (
  <>
    <div className="mx-4 grid gap-x-6 gap-y-8 grid-cols-2 border-b border-gray-900/10 pb-12">
      <Controller
        control={control}
        name={`guests.${index}.isRepresentative`}
        render={({ field }) => (
          <Checkbox
            className="col-span-2"
            label={t`Is Representative`}
            checked={field.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              field.onChange(e.target.checked);
            }}
          />
        )}
      />
      <StackedInputGroup
        label={t`Family Name`}
        name={`guests.${index}.lastName`}
        required
        options={{ required: t`Family Name is required` }}
      />
      <StackedInputGroup
        label={t`Given Name`}
        name={`guests.${index}.firstName`}
        required
        options={{ required: t`Given Name is required` }}
      />
      <StackedInputGroup
        label={t`フリガナ（姓）`}
        name={`guests.${index}.lastNameFurigana`}
      />
      <StackedInputGroup
        label={t`フリガナ（名）`}
        name={`guests.${index}.firstNameFurigana`}
      />
      <StackedInputGroup
        label={t`From Temairazu`}
        className="col-span-2"
        name={`guests.${index}.guestName`}
        options={{
          disabled: true,
        }}
      />
      <StackedInputGroup
        label={t`Date Of Birth`}
        name={`guests.${index}.dob`}
        type="date"
      />
      <StackedSelectInputGroup
        label={t`Gender`}
        selectProps={{ name: `guests.${index}.gender`, options: genderOptions }}
      />
      <StackedInputGroup
        label={t`Nationality`}
        name={`guests.${index}.nationality`}
      />
    </div>

    <div className="mt-10 mx-4 grid gap-x-6 gap-y-8 grid-cols-2 border-b border-gray-900/10 pb-12">
      <h2 className="text-base col-span-full font-semibold leading-7 text-gray-900">
        <Trans>Contact</Trans>
      </h2>
      <StackedInputGroup label={t`Email`} name={`guests.${index}.email`} />
      <StackedPhoneInputGroup
        label={t`Telephone`}
        name={`guests.${index}.phone`}
      />
    </div>

    <div className="mt-10 mx-4 grid gap-x-6 gap-y-8 grid-cols-2">
      <h2 className="text-base col-span-full font-semibold leading-7 text-gray-900">
        <Trans>Address</Trans>
      </h2>

      <StackedCountryDropdown
        label={t`Country`}
        name={`guests.${index}.country`}
      />
      <StackedInputGroup
        label={t`Postal Code`}
        name={`guests.${index}.zipcode`}
      />
      <StackedProvinceDropdown
        label={t`State/Prefecture/Province`}
        name={`guests.${index}.province`}
        countryName={`guests.${index}.country`}
      />
      <StackedInputGroup
        label={t`Address 1`}
        name={`guests.${index}.address1`}
      />
      <StackedInputGroup
        label={t`Address 2`}
        name={`guests.${index}.address2`}
      />
      <StackedInputGroup label={t`City`} name={`guests.${index}.city`} />
      <StackedSelectInputGroup label={t`Age Group`} selectProps={{ name: `guests.${index}.ageGroup`, options: toValueLabelList(AgeGroup)}} />
      <Controller
        control={control}
        name={`guests.${index}.optOutEmail`}
        render={({ field }) => (
          <Checkbox
            className="col-span-2"
            label={t`Do Not Send Email`}
            checked={field.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              field.onChange(e.target.checked);
            }}
          />
        )}
      />
    </div>
  </>
);
