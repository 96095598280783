import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { t } from '@lingui/macro';

import { SelectInputGroup } from "../../../components/forms/Select";
import { usePropertyService } from "../../../services/usePropertyService";
import { useReservationMemoStore } from "../../../store/reservationMemoStore";
import { ReservationRouteParam } from "../routes/ReservationDetailView";
import { SelectOption } from "../../../components/forms/Select/types";

export function MemoCategoryDropDown() {
  const memoCategories = useReservationMemoStore(state => state.memoCategories);
  const memoCategory = useReservationMemoStore(state => state.memoCategory);
  const setMemoCategory = useReservationMemoStore(state => state.setMemoCategory);

  const params = useParams<ReservationRouteParam>();

  const { getReservationMemoCategories } = usePropertyService();

  useEffect(() => {
    getReservationMemoCategories(params.propertyId || "");
  }, []);

  const onSelectHandler = async (data: SelectOption) => {
    const category = memoCategories.find(category => category.id === data.value);

    if (category) {
      setMemoCategory(category);
    }
  }

  return <SelectInputGroup
    selectProps={{
      options: memoCategories.map(memo => {
        return {
          value: memo.id,
          label: memo.name,
        }
      }),
      selected: memoCategory && memoCategory.name,
      inlineLabel: t`Select a category`,
      onChange: onSelectHandler,
    }}
    label={t`Memo Category`}
  />
}

