import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { classNames, getNestedValue } from "../../../utils";
import { useFormContext } from "react-hook-form";

import { baseClassNames, defaultColorClassNames, disabledColorClassNames, errorColorClassNames } from "./Input";
import { StackedInputGroupProps } from "./types";

export function StackedInputGroup({
  name,
  label,
  type = "text",
  helpText,
  options,
  className,
  required = false,
}: StackedInputGroupProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [key: string]: string }>();
  const error = getNestedValue(errors, name)?.message;
  const showHelpText = helpText && !error;
  let activeClassNames = classNames(baseClassNames, "w-full");
  if (options?.disabled) {
    activeClassNames = classNames(activeClassNames, disabledColorClassNames);
  } else if (error) {
    activeClassNames = classNames(activeClassNames, errorColorClassNames);
  } else {
    activeClassNames = classNames(activeClassNames, defaultColorClassNames);
  }

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 mt-1.5"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>

      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          {...register(name, options)}
          className={activeClassNames}
          type={type}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {error}
        </p>
      )}
      {showHelpText && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {helpText}
        </p>
      )}
    </div>
  );
}
