import { apiClient } from "./createApiClient";
import { 
  InventoryResponseDTO, 
  BulkInventoryResponseDTO,
  RoomInventoryDetailForCalendarDTO,
  SummaryInventoryResponseDTO,
  AvailabilityResponseDTO,
  CreateInventoryRequestDTO,
  ExtendInventoryDTO,
} from '@/types/roomInventory.dto';

export interface CreateInventoryRequest {
  roomTypeId: number;
  startDate: string;
  endDate: string;
}

export interface ExtendInventoryRequest {
  startDate: string;
  endDate: string;
}

export interface RoomInventoryCalendarQueryParam {
  startDate: string;
  endDate: string;
}

export function createRoomInventoryCalendarClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/inventory`;

  return {
    /**
     * Initialize inventory for specific room types
     */
    initializeInventory: async (
      inventoryRequests: CreateInventoryRequestDTO,
    ): Promise<InventoryResponseDTO> => apiClient.post(`${baseUrl}/initialize`, { inventoryRequests }),

    /**
     * Extend inventory for all room types in a property
     */
    extendInventory: async (
      request: ExtendInventoryDTO,
    ): Promise<InventoryResponseDTO> => apiClient.post(`${baseUrl}/extend`, request),

    /**
     * Check availability for a specific room type
     */
    checkRoomTypeAvailability: async (
      roomTypeId: number,
      params: {
        checkInDate: string;
        checkOutDate: string;
        roomsNeeded?: number;
      }
    ): Promise<AvailabilityResponseDTO> => apiClient.get(`${baseUrl}/availability/${roomTypeId}`, { params }),

    /**
     * Get room inventory calendar data for a date range
     */
    getRoomInventoryCalendar: async (
      params: {
        startDate: string;
      }
    ): Promise<{
      message: string;
      inventory: BulkInventoryResponseDTO;
      summary: SummaryInventoryResponseDTO;
    }> => apiClient.get(`${baseUrl}/calendar`, params),

    /**
     * Get detailed inventory information for a specific room inventory
     */
    getRoomInventoryDetail: async (
      roomInventoryId: number,
    ): Promise<{
      message: string;
      inventory: RoomInventoryDetailForCalendarDTO;
    }> => apiClient.get(`${baseUrl}/calendar/${roomInventoryId}`),

    /** 
     * Adjust room inventory total available
     */
    adjustRoomInventoryTotalAvailability: async (
      roomInventoryId: number,
      totalAvailableRooms: number,
    ): Promise<{
      message: string;
    }> => apiClient.post(`${baseUrl}/calendar/${roomInventoryId}/adjust`, { totalAvailableRooms }),
  };
} 