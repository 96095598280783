import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useSlideOverStore } from "../../store/slideOverStore";
import { BaseSlideOver } from "./BaseSlideOver";

export type SlideOverProps = {
  onClose: (state?: any) => void;
  onOpen?: () => void;
  afterLeave?: () => void;
  onReset?: () => void;
  open: boolean;
  disableSubmitButton?: boolean;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  content?: ReactJSXElement | ReactJSXElement[];
  title: string | ReactJSXElement;
  description?: string;
  saveButtonName?: string;
  resetButtonName?: string;
  hideCancelButton?: boolean;
  hideSaveButton?: boolean;
  children?: ReactJSXElement;
};

export function SlideOver(props: SlideOverProps) {
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);

  return (
    <BaseSlideOver
      {...props}
      afterLeave={() => {
        setSlideOver(undefined);
        props.afterLeave?.();
      }}
    />
  );
}
