import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { Alert, DatePicker, Form, Input, Select, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { Trans, t } from "@lingui/macro";

import { OTAState, useOtaStore } from "@/store/otaStore";
import { useOtaService } from "@/services/useOtaService";
import { ReservationsQueryParam } from "@/api/createReservationClient";
import { Button } from "@/components/elements";

import { defaultReservationQueryParam } from "../constants";

const { RangePicker } = DatePicker;

const { Title } = Typography;

const siderStyle: React.CSSProperties = {
  lineHeight: '120px',
  color: '#2d2d2d',
  height: "100%",
  background: "none",
  display: "inline-block",
  padding: "10px",
};

export type CustomSearchInputs = {
  searchDateType: string;
  searchDates: Dayjs[];
  otas: string[]; // OTA IDs
  reservationStatus?: string;
  paymentStatus?: string;
}

const searchDateTypes = {
  CHECK_IN_DATE: "checkInDate",
  BOOKING_DATE: "bookingDate",
}

export function ReservationListFilter() {
  const [_, setSearchParams] = useSearchParams();
  const params = useParams<{propertyId: string}>();

  const otas = useOtaStore((state: OTAState) => state.otas);
  const { getOtas } = useOtaService(params.propertyId ?? "");

  useEffect(() => {
    getOtas();
  }, []);

  const otaBookingIdSearchForm = useForm<{ otaBookingId: string }>({
    defaultValues: { otaBookingId: "" },
  });

  const handleOTABookingIdSearch: SubmitHandler<{ otaBookingId: string }> = async data => {
    const queryParam: ReservationsQueryParam = {
      limit: "1",
      page: "1",
      temairazuOTABookingId: data.otaBookingId,
    }
    setSearchParams(queryString.stringify(queryParam));
  }

  const initialValues: CustomSearchInputs = {
    searchDateType: searchDateTypes.CHECK_IN_DATE,
    searchDates: [
      dayjs(),
      dayjs().add(1, "month")
    ],
    otas: [],
  }

  const { handleSubmit, control, formState: { isSubmitting }, reset } = useForm<CustomSearchInputs>({
    defaultValues: initialValues,
  });

  const handleCustomSearch: SubmitHandler<CustomSearchInputs> = data => {
    const filterParams: ReservationsQueryParam = {
      limit: "10",
      page: "1",
    }

    if (data.searchDates && data.searchDates.length) {
      const startSearchDate = data.searchDates[0].format("YYYY-MM-DD");
      const endSearchDate = data.searchDates[1].format("YYYY-MM-DD");

      if (data.searchDateType === searchDateTypes.CHECK_IN_DATE) {
        filterParams.checkInDateStart = startSearchDate;
        filterParams.checkInDateEnd= endSearchDate;
      }

      if (data.searchDateType === searchDateTypes.BOOKING_DATE) {
        filterParams.bookingDateStart = startSearchDate;
        filterParams.bookingDateEnd = endSearchDate;
      }
    }

    if (data.otas && data.otas.length) filterParams.otas = data.otas;
    if (data.paymentStatus) filterParams.paymentStatus = data.paymentStatus;

    setSearchParams(queryString.stringify(filterParams));
  };

  const handleResetFilter = () => {
    setSearchParams(queryString.stringify(defaultReservationQueryParam));
    reset(initialValues);
  }

  return (
    <Sider className="filter-sider" style={siderStyle}>
      <Title level={3}>Filter</Title>

      <Form
        layout="vertical"
        disabled={otaBookingIdSearchForm.formState.isSubmitting}
        title={t`Direct search`}
        onFinish={otaBookingIdSearchForm.handleSubmit(handleOTABookingIdSearch)}
      >
        <Form.Item label={t`Search OTA Booking ID`}>
          <Controller
            control={otaBookingIdSearchForm.control}
            rules={{
              required: t`OTA Booking ID is required`
            }}
            name="otaBookingId"
            render={({ field }) => <Input { ...field } placeholder="87436633..." />}
          />
          {otaBookingIdSearchForm.formState.errors.otaBookingId &&
          <Alert type="error" message={otaBookingIdSearchForm.formState.errors.otaBookingId.message} />}
        </Form.Item>

        <Form.Item>
          <Button
            loading={otaBookingIdSearchForm.formState.isSubmitting}
            type="primary"
          >
            <Trans>
              Search
            </Trans>
          </Button>
        </Form.Item>
      </Form>

      <Form
        layout="vertical"
        disabled={otaBookingIdSearchForm.formState.isSubmitting}
        title={t`Direct search`}
        onFinish={handleSubmit(handleCustomSearch)}
      >
        <Form.Item label={t`Date types`}>
          <Controller
            control={control}
            name="searchDateType"
            defaultValue={initialValues.searchDateType}
            render={({ field }) => (
              <Select
                style={{ minWidth: 100 }}
                defaultValue={initialValues.searchDateType}
                onChange={(e) => field.onChange(e)}
                options={[
                  { label: t`Check In`, value: searchDateTypes.CHECK_IN_DATE },
                  { label: t`Booking Date`, value: searchDateTypes.BOOKING_DATE },
                ]}
              />
            )}
          />
        </Form.Item>
        <Form.Item>
          <Controller
            control={control}
            name="searchDates"
            defaultValue={initialValues.searchDates}
            render={({ field }) => (
              <RangePicker defaultValue={[dayjs(), dayjs().add(1, "month")]} onChange={(dates) => {
                field.onChange(dates);
              }} />
            )}
          />
        </Form.Item>

        <Form.Item label={t`OTA`}>
          <Controller
            control={control}
            name="otas"
            render={({ field }) => (
              <Select
                mode="multiple"
                style={{minWidth: 100}}
                onChange={(e) => field.onChange(e)}
                options={otas?.map(ota=> {
                  return { value: ota.id, label: ota.name, key: ota.id }
                })}
              />
            )}
          />
        </Form.Item>

        <Form.Item label={t`Payment Status`}>
          <Controller
            control={control}
            name="paymentStatus"
            render={({ field }) => (
              <Select
                style={{ minWidth: 100 }}
                onChange={(e) => field.onChange(e)}
                options={[
                  { label: t`Paid`, value: "paid"},
                  { label: t`Not Paid`, value: "not paid"},
                ]}
              />
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button loading={isSubmitting} type="primary">
            <Trans>
              Search 
            </Trans>
          </Button>
        </Form.Item>


        <Form.Item>
          <Button
            loading={otaBookingIdSearchForm.formState.isSubmitting}
            type="primary"
            onClick={handleResetFilter}
          >
            <Trans>
              Reset filter 
            </Trans>
          </Button>
        </Form.Item>
      </Form>
    </Sider>
  );
}
