import React from 'react';
import { Trans } from '@lingui/macro';
import {Visibility} from "@/api/createRoomCalendarClient"

export type VisibilityOption = 'both' | Visibility.Event | Visibility.Reservation;

interface VisibilityToggleProps {
  selected: VisibilityOption;
  onChange: (option: VisibilityOption) => void;
}

export const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ selected, onChange }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <button
        type="button"
        className={`px-2 py-1 text-xs font-medium rounded-l-md ${
          selected === 'both'
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-white text-gray-700 hover:bg-gray-50'
        } border border-gray-200`}
        onClick={() => onChange('both')}
      >
        <Trans>Both</Trans>
      </button>
      <button
        type="button"
        className={`px-2 py-1 text-xs font-medium ${
          selected === "Event"
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-white text-gray-700 hover:bg-gray-50'
        } border-t border-b border-gray-200`}
        onClick={() => onChange(Visibility.Event)}
      >
        <Trans>Events</Trans>
      </button>
      <button
        type="button"
        className={`px-2 py-1 text-xs font-medium rounded-r-md ${
          selected === "Reservation"
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-white text-gray-700 hover:bg-gray-50'
        } border border-gray-200`}
        onClick={() => onChange(Visibility.Reservation)}
      >
        <Trans>Reservations</Trans>
      </button>
    </div>
  );
};
