import dayjs from 'dayjs';
import { DatePicker } from "@/components/forms/Datepicker";
import { useRoomInventoryCalendar } from '../hooks/useRoomInventoryCalendar';
import { t } from "@lingui/macro";
import { useSearchParams } from 'react-router-dom';
import { useSlideOverStore } from "@/store/slideOverStore";
import RoomInventoryDetailSlideOver from './RoomInventoryDetailSlideOver';
import { RoomInventoryForCalendarDTO } from '@/types/roomInventory.dto';
import { useState, useEffect } from 'react';
import { RoomInventoryDailySummaryPopup } from './RoomInventoryDailySummaryPopup';
import { OVERBOOKED } from '../hooks/useRoomInventoryCalendar';
import { Loading } from '@/components/elements/Loading';

export function RoomInventoryCalendar() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { 
    inventory,
    summary,
    updateFilters,
    isInventoryLoading,
  } = useRoomInventoryCalendar();
  const setSlideOver = useSlideOverStore((state) => state.setSlideOver);
  
  // Add state for popup
  const [summaryPopupAnchor, setSummaryPopupAnchor] = useState<HTMLElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const inventoryId = searchParams.get("inventoryId");

  useEffect(() => {
    if (inventoryId && inventory) {
      for (const [roomTypeName, roomData] of Object.entries(inventory)) {
        const dayData = roomData.find(data => data.id === parseInt(inventoryId));
        if (dayData) {
          setSlideOver(
            <RoomInventoryDetailSlideOver
              date={dayData.date}
              roomTypeName={roomTypeName}
              inventoryId={parseInt(inventoryId)}
            />
          );
          break;
        }
      }
    }
  }, [inventoryId, inventory, setSlideOver]);

  const generateDateColumns = (startDate: Date) => {
    const columns = [];
    const startDayjs = dayjs(startDate);
    
    for (let i = 0; i < 10; i++) {
      const date = startDayjs.add(i, 'day');
      columns.push({
        key: date.format('YYYY-MM-DD'),
        header: date.format('ddd MM/DD'),
      });
    }
    return columns;
  };

  const dateToUse = searchParams.get("startDate") || Date();
  const [startDate, setStartDate] = useState(dayjs(dateToUse).toDate());
  const dateColumns = generateDateColumns(startDate);

  const renderTableHeader = () => (
    <thead>
      <tr className="divide-x divide-gray-200">
        <th className="w-72 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-2 pl-2 pr-1 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-3 lg:pl-4">
          <DatePicker
            selectedDate={startDate}
            onChange={handleDateChange}
          />
        </th>
        <th className="w-24 sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-2 pl-2 pr-1 text-left text-sm font-semibold text-gray-900" />
        {dateColumns.map((column) => (
          <th
            key={column.key}
            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2 text-center text-sm font-semibold text-gray-900"
          >
            {column.header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderSummaryRows = () => {
    if (!summary) return null;

    return (
      <tr className="divide-x divide-gray-200">
        <td className="px-3 py-2 text-sm bg-gray-50 border-b border-gray-200">
          <div className="font-medium">{t`Total`}</div>
          <div className="text-xs text-gray-500">
          </div>
        </td>
        <td className="px-3 py-2 text-sm border-b border-gray-200">
          <div>{t`Available`}</div>
          <div>{t`Booked`}</div>
        </td>
        {dateColumns.map(column => {
          return (
            <td 
              key={column.key} 
              className={`px-3 py-2 text-sm text-center border-b border-gray-200 cursor-pointer ${
                summary[column.key]?.warnings && Object.keys(summary[column.key]?.warnings).length > 0 
                  ? 'bg-orange-50 hover:bg-orange-100' 
                  : 'hover:bg-gray-50'
              }`}
              onClick={(e) => handleSummaryClick(e, column.key)}
            >
              <div>{summary[column.key]?.totalAvailableRooms || 0}</div>
              <div className="text-gray-500">
                {summary[column.key]?.totalSoldRooms || 0}
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  // Add handler for summary cell clicks
  const handleSummaryClick = (event: React.MouseEvent<HTMLTableCellElement>, date: string) => {
    setSelectedDate(date);
    setSummaryPopupAnchor(event.currentTarget);
  };

  // Add handler to close popup
  const handleClosePopup = () => {
    setSummaryPopupAnchor(null);
    setSelectedDate(null);
  };

  const handleCellClick = async (inventoryId: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("inventoryId", inventoryId.toString());
    setSearchParams(newParams);
  };

  const handleDateChange = (date: Date) => {
    setStartDate(date);
    updateFilters({ startDate: dayjs(date).format('YYYY-MM-DD') });
  };

  const renderInventoryRows = () => {
    if (!inventory) return null;

    return Object.entries(inventory).map(([roomTypeName, roomData]) => (
      <tr key={roomTypeName} className="divide-x divide-gray-200 border-b border-gray-200">
        <td className="w-48 px-3 py-2 text-sm bg-gray-50 border-b border-gray-200">
          <div className="font-medium">{roomTypeName}</div>
          <div className="text-xs text-gray-500">
            Total Rooms: {roomData[0]?.totalRooms || 0}
          </div>
        </td>
        <td className="px-3 py-2 text-sm border-b border-gray-200">
          <div>{t`Available`}</div>
          <div>{t`Booked`}</div>
        </td>
        {dateColumns.map(column => {
          const dayData = roomData.find(data => data.date === column.key) as RoomInventoryForCalendarDTO;
          if (!dayData) return null;
          return (
            <td 
              key={column.key} 
              className={`px-3 py-2 text-sm text-center border-b border-gray-200 cursor-pointer ${
                dayData.message?.message === OVERBOOKED
                  ? 'bg-red-50 hover:bg-red-100'
                  : dayData?.message?.message
                    ? 'bg-yellow-50 hover:bg-yellow-100'
                    : 'hover:bg-gray-50'
              }`}
              onClick={() => handleCellClick(dayData.id)}
            >
              <div>{dayData?.totalAvailableRooms || 0}</div>
              <div className="text-gray-500">
                {dayData?.totalSoldRooms || 0}
              </div>
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-4 flow-root relative">
        {isInventoryLoading && (
          <div className="absolute inset-0 bg-white/80 backdrop-blur-sm z-10 flex items-center justify-center">
            <Loading />
          </div>
        )}
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0 divide-y divide-gray-300 border border-gray-200">
              {renderTableHeader()}
              <tbody className="divide-y divide-gray-200 bg-white">
                {renderSummaryRows()}
                {renderInventoryRows()}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add popup */}
      {summaryPopupAnchor && selectedDate && summary && (
        <RoomInventoryDailySummaryPopup
          summary={summary}
          date={selectedDate}
          onClose={handleClosePopup}
          anchorEl={summaryPopupAnchor}
          onRoomTypeClick={handleCellClick}
        />
      )}
    </div>
  );
}
