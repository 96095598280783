import { Input } from "./Input";
import { InputGroupProps } from "./types";

export function InputGroup({ label, inputProps, error }: InputGroupProps) {
  return (
    <div className="grid grid-cols-3 gap-4 space-y-0 px-6 py-5">
      <div>
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-6 text-gray-900 mt-1.5"
        >
          {label}
        </label>
      </div>
      <div className="col-span-2">
        <Input {...inputProps} />
        {error && (
        <p className="mt-2 text-sm text-red-600" id={`${inputProps.name}-error`}>
          {error}
        </p>
      )}
      </div>
    </div>
  );
}
