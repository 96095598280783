import {
  Listbox,
  Transition,
  ListboxOptions,
  ListboxOption,
  ListboxButton,
} from "@headlessui/react";
import { classNames } from "../../../utils";
import {
  ChevronDownIcon,
  CheckIcon as MicroCheckIcon,
} from "@heroicons/react/16/solid";
import { SelectProps } from "./Select";

export function SelectBadge({
  onChange,
  options,
  inlineLabel,
  selected,
  size = "md",
  disabled = false,
}: SelectProps) {
  const selectedLabel = options.find(
    (option) => option.value === selected
  )?.label;
  let sizeClasses = "";

  if (size === "xs") {
    sizeClasses = "px-1.5 py-0.5 text-xs";
  } else if (size === "sm") {
    sizeClasses = "px-2 py-1 text-sm";
  } else if (size === "lg") {
    sizeClasses = "px-3 py-2 text-sm";
  } else {
    sizeClasses = "px-2.5 py-1.5 text-sm";
  }

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative">
            <div
              className={`inline-flex divide-x divide-gray-700 rounded-md shadow-sm ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <div
                className={`inline-flex items-center gap-x-1.5 rounded-l-md bg-gray-600 text-white shadow-sm ${sizeClasses}`}
              >
                <p className="font-semibold">{selectedLabel || inlineLabel}</p>
              </div>
              <ListboxButton
                className={`inline-flex items-center rounded-l-none rounded-r-md bg-gray-600 ${
                  !disabled && "hover:bg-gray-700"
                } ${sizeClasses}`}
                disabled={disabled}
              >
                <ChevronDownIcon
                  className={`${
                    size === "xs" ? "h-3 w-3" : "h-5 w-5"
                  } text-white`}
                  aria-hidden="true"
                />
              </ListboxButton>
            </div>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute left-0 z-20 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-gray-600 text-white" : "",
                        !focus ? "text-gray-900" : "",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, focus }) => (
                      <div className="flex flex-col">
                        <p
                          className={selected ? "font-semibold" : "font-normal"}
                        >
                          {option.label}
                        </p>
                        {selected ? (
                          <span
                            className={focus ? "text-white" : "text-gray-600"}
                          >
                            <MicroCheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
