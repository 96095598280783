import { Room } from "../../types/room";
import { RoomTypeDTO } from "../../types/roomType.dto";
import { apiClient } from "./createApiClient";

type RoomTypeResponse = {
  message: string;
  error?: string;
  roomTypes: RoomTypeDTO[];
};

type RoomResponse = {
  message: string;
  error?: string;
  room: Room;
}

export function createRoomClient(propertyId: string) {
  const baseUrl = `/property/${propertyId}/room-types`;

  const getRoomTypes = () =>
    apiClient.get<RoomTypeResponse>(baseUrl);

  const getRoom = (id: number, roomId: number) =>
    apiClient.get<RoomResponse>(`${baseUrl}/${id}/rooms/${roomId}`);

  const updateRoom = (id: number, roomId: number, room: Partial<Room>) =>
    apiClient.patch<Partial<Room>, RoomResponse>(`${baseUrl}/${id}/rooms/${roomId}`, room);

  return {
    getRoomTypes,
    getRoom,
    updateRoom,
  };
}
