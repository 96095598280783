import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { Notifications } from '../notifications/Notifications';
import { useSlideOverStore } from '../../store/slideOverStore';

export const MainLayout: React.FC = () => {
  const slideOverContext = useSlideOverStore((state) => state.slideOver);

  return (
    <div className="min-h-full min-w-[1024px]">
      <div className="flex">
        <Sidebar />
        <div className="flex-grow">
          <main>
            <Outlet />
          </main>
        </div>
      </div>
      <Notifications />
      {slideOverContext}
    </div>
  );
};

