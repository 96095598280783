import { useState, useRef } from 'react';

const collapsedSidebarThreshold = 150;

export const useSidebar = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(220);
  const [peeked, setPeeked] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const isHovered = useRef<boolean>(false);

  const toggleSidebar = () => setCollapsed(!collapsed);

  const handleMouseOver = () => {
    if (collapsed) {
      isHovered.current = true;
      setPeeked(true);
    }
  };

  const handleMouseOut = () => {
    isHovered.current = false;
    delayTimer.current = setTimeout(() => {
      if (collapsed && !isHovered.current) {
        setPeeked(false);
      }
    }, 500);
  };

  const resizeSidebar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const oldWidth = width;
    const onMouseMove = (e: MouseEvent) => {
      setWidth(e.clientX);
      setIsResizing(true);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener(
      "mouseup",
      (e: MouseEvent) => {
        if (e.clientX < collapsedSidebarThreshold) {
          setWidth(oldWidth);
          setCollapsed(true);
        }
        setIsResizing(false);
        document.removeEventListener("mousemove", onMouseMove);
      },
      { once: true }
    );
  };

  return {
    width,
    collapsed,
    peeked,
    isResizing,
    handleMouseOver,
    handleMouseOut,
    toggleSidebar,
    resizeSidebar
  };
};

