import React, { useEffect, useRef, useState, useCallback } from 'react';
import { RoomEventDTO } from '@/types/roomEvent.dto';
import { Button } from '@/components/elements/Button';
import { t } from '@lingui/macro';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface EventPopupProps {
  event: RoomEventDTO;
  onEdit: () => void;
  onDelete: (event: RoomEventDTO) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

export const EventPopup: React.FC<EventPopupProps> = ({ event, onEdit, onDelete, onClose, anchorEl }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [popupStyle, setPopupStyle] = useState<{[k: string]: string}>(
    { visibility: 'hidden' }
  );

  const updatePopupPosition = useCallback(() => {
    if (anchorEl && popupRef.current) {
      const anchorRect = anchorEl.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();
      
      let top = anchorRect.bottom + window.scrollY;
      let left = anchorRect.left + window.scrollX;

      // Adjust if popup would go off-screen
      if (top + popupRect.height > window.innerHeight) {
        top = anchorRect.top - popupRect.height + window.scrollY;
      }
      if (left + popupRect.width > window.innerWidth) {
        left = anchorRect.right - popupRect.width + window.scrollX;
      }

      setPopupStyle({
        top: `${top}px`,
        left: `${left}px`,
        visibility: 'visible',
      });
    }
  }, [anchorEl]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node) && event.target !== anchorEl) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', updatePopupPosition);
    window.addEventListener('scroll', updatePopupPosition);

    updatePopupPosition();

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', updatePopupPosition);
      window.removeEventListener('scroll', updatePopupPosition);
    };
  }, [onClose, anchorEl, updatePopupPosition]);

  const handleEdit = () => {
    onEdit();
    onClose();
  };

  const handleDelete = () => {
    onDelete(event);
    onClose();
  };

  return (
    <div 
      ref={popupRef} 
      style={{...popupStyle, position: "fixed"}}
      className="bg-white rounded-lg shadow-lg p-4 max-w-sm z-50 border border-gray-200"
    >
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        aria-label="Close"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
      <h3 className="text-lg font-semibold mb-2">{event.eventName}</h3>
      <p className="text-sm text-gray-600 mb-1">{t`Event Type: ${event.eventType}`}</p>
      <p className="text-sm text-gray-600 mb-1">{t`Start: ${new Date(event.dateTimeStart).toLocaleString()}`}</p>
      <p className="text-sm text-gray-600 mb-2">{t`End: ${new Date(event.dateTimeEnd).toLocaleString()}`}</p>
      <p className="text-sm text-gray-600 mb-4">{event.description}</p>
      <div className="flex justify-end space-x-2">
        <Button onClick={handleEdit} size="sm">{t`Edit`}</Button>
        <Button onClick={handleDelete} type="danger" size="sm">{t`Delete`}</Button>
      </div>
    </div>
  );
};